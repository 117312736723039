import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-colum-image',
  templateUrl: './colum-image.component.html',
  styleUrls: ['./colum-image.component.scss']
})
export class ColumImageComponent implements OnInit {

  @Input() images: string[] ;
  // slideIndex = 0;

  constructor() { }

  ngOnInit(): void {
    // if (this.images.length <= 1) {
    //   return;
    // } else {
    //   this.slideIndex++;
    //   console.log(this.slideIndex);
    //   if (this.slideIndex === this.images.length) {
    //     this.slideIndex = 0;
    //   }
      // this.enableImgSlideshow();
  }

  // enableImgSlideshow(): void {
  //   setInterval(() => {
  //     this.slideIndex++;
  //     if (this.slideIndex === this.images.length) {
  //       this.slideIndex = 0;
  //     }
  //   }, 10000);
  // }

}
