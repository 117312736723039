import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  faqs = [
    {
      question: 'HOW LONG DO YOUR PASTRIES STAY FRESH?',
      answer: `We don’t add preservatives to our pastries. As a result, they are best enjoyed fresh immediately upon receipt!`,
      isVisible: false
    }, {
      question: 'ARE ANY OF YOUR PASTRIES NUT-FREE',
      answer: `Many of our products are nut-free. When nuts are an ingredient, it is indicated in the product description.`,
      isVisible: false
    }, {
      question: 'DO YOU HAVE ANY GLUTEN-FREE OR VEGAN OPTIONS?',
      answer: `Vegan or gluten-free options are listed on our daily menu. However, upon special request, a selection of our cakes can be made gluten-free and/or vegan.`,
      isVisible: false
    }, {
      question: 'HOW MANY PEOPLE DOES A CAKE FEED?',
      answer: `We cut our daily cakes into twelve generous pieces. Our custom cakes may vary in quantity per size and shape, if you want to make a special order, please contact us.`,
      isVisible: false
    }, {
      question: 'CAN I INCLUDE PERSONALIZED MESSAGE IN MY ORDER?',
      answer: `Yes, with a notice on advance we can write a short message on your cake.`,
      isVisible: false
    }, {
    //   question: 'WHAT ARE THE DELIVERY PARAMETERS?',
    //   answer: `Our service area in Lincoln is surrounded by North 70th Street, Superior Street, N First Street, and South Street. Check if your address is in the delivery area by clicking into the 'Order' section.`,
    //   isVisible: false
    // }, {
      question: 'DO YOU OFFER DISCOUNTS?',
      answer: `Yes, we offer special discounts to help local organizations with their fundraising events. We also provide them with inventory and allow payment after the event is over. Get in touch with us to find out more.`,
      isVisible: false
    // }, {
    //   question: 'WHAT PAYMENT OPTIONS ARE AVAILABLE?',
    //   answer: `Our website is not yet enabled for online payments. On store pickup, we accept cash or card payments (Visa, MasterCard, American Express, and Discover). For delivery orders, customers can pay the delivery person in cash upon delivery or by phone while on the order confirmation call.`,
    //   isVisible: false
    // }, {
    //   question: 'HOW WILL I RECEIVE MY INVOICE?',
    //   answer: `We will generate your invoice along with your order.`,
    //   isVisible: false
    // }, {
    //   question: 'HOW DO I CANCEL MY ORDER?',
    //   answer: `If you wish to cancel your order, contact us as soon as possible. If the order has not been processed, Misky may still be able to cancel it.`,
    //   isVisible: false
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

  toggleElement(index: number): void {
    this.faqs[index].isVisible = !this.faqs[index].isVisible;
  }

}
