
 <app-colum-image class="mobile-colimg"   [images]="['delivery-order', 'donas-order', 'alfajores-order']"></app-colum-image>
<!-- Contenedor -->
<div class="content-text contentfade-effect">
  <!-- Primer subtitulo -->
  <h3 class="subtitlepink">Order Online</h3>
  <!-- Primer contenido -->
  <p>We are happy to offer food delivery online!</p> 
  <p>Now you can enjoy at home our hand made pastry, which taste delicious and reflect the heritage of All Latin American culinary landscape.</p>
  <!-- Botones Delivery,PU -->
  <div class="botones">
    <button class="containerb" (click)="saveLS('delivery')" [disabled]="true">
      <h4 class="white movingtext">Delivery | </h4>
      <img class="bdelivery"  src="assets/Icons/delivery.svg">
    </button>

    <button class="containerb" (click)="saveLS('pickup')">
      <h4 class="white fadetext">Pick Up | </h4>
      <img class="bstore"  src="assets/Icons/store.svg">
    </button>
  </div>
  <!-- Segundo contenido -->
  <p class="p3mediumpink">Delivery details</p>
  <p>To process your order, we will request the following information:</p> 
  <div class="dev-info">
    <h4 class="boldpink"> • Telephone number, email address</h4>
    <h4 class="small">To contact you with your confirmation.</h4>
    <h4 class="boldpink"> • Your Shipping Address</h4>
    <h4 class="small">Check if your address is in the delivery area allowed in Lincoln.
      <a href="https://www.google.com/maps/d/u/0/edit?mid=1B_bKslLOu-08eIBtCmPC6Xx-ucX3_9b7&fbclid=IwAR3jtmBLPyCjNzrajPur5HDDmFtCDMdY_8w3luHp2OBX6_VSWyVEnzbbxPA&ll=40.82452722201206%2C-96.67495765000001&z=13" target="_blank">
        <span class="map">Show map</span>
      </a>
    </h4>
    <h4 class="boldpink"> • Accept Delivery fee</h4>
    <h4 class="small">Delivery fee of $ 5.00 if is close to the store and + $ 5.00 if is further but within the allowed service area.</h4>
    <h4 class="boldpink"> • Accept Minimum order value</h4>
    <h4 class="small">Minimum order value of $ 20.00 excluding delivery fee.</h4>
    <h4 class="boldpink"> • Payment method</h4>
    <h4 class="small">Pay by phone while confirming the order (card payment: Visa, MasterCard, American Express and Discover), or in cash upon order delivery.</h4>
  </div>
</div>

<!-- Image Colum -->
  <app-colum-image class="aside-colimg"  [images]="['delivery-order', 'donas-order', 'alfajores-order']"></app-colum-image>

