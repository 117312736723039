<div class="content">
  <div *ngFor="let section of menuSections; index as i" class="section-wrapper flex-row">
    <div class="single-section flex-column">
      <img class="title" [src]="'assets/' + section.url + '-title.png'">
      <img class="product" [src]="'assets/' + section.url + '.png'">
      <div class="button-menu" [routerLink]="[section.url]">
       <h6 class="white">{{ section.title }}</h6>
      </div>
    </div>

    <div class="single-section-ipadpro flex-row">
      <div class="flex-column boxipad">
        <img class="title" [src]="'assets/' + section.url + '-title.png'">
        <div class="button-menu" [routerLink]="[section.url]">
        <h6 class="white">{{ section.title }}</h6>
        </div>
      </div>
      <img class="product" [src]="'assets/' + section.url + '.png'">
    </div>
  </div>

</div>
