<!-- headline -->
<div class="headline" (click)="close()">
  <img class="close" src="assets/Icons/closeb-pink-1view.svg">
  <img class="fill" src="assets/Icons/closeb-pink-2view.svg">
</div>


<!-- Container -->
<div class="container-detail">
  <!-- Column Image -->
  <div class="circle flex-column">
    <img class="img-product" [src]="'assets/Menu/' + productData.image">
  </div>
  <!-- Product Detail -->
  <div class="detail flex-column">
    <h6 class="name">{{productData.name}}</h6>
    <h1 class="price">$ {{productData.price | number: '1.2-2' }}</h1>
    <div class="description">
      <h4 class="small">{{productData.description}}</h4>
    </div>

    <div class="info-options flex-row">
      <div class="op1 flex-row">
        <img class="icon" src="assets/Icons/Gluten.svg">
        <div class="gluten">
          <h4 class="boldsmall">Gluten Free</h4>
          <h1 class="little">{{productData.gluten}}</h1>
        </div>
     </div>
      <div class="op2 flex-row">
        <img class="icon" src="assets/Icons/Vegan.svg">
        <div class="vegan">
          <h4 class="boldsmall">Vegan</h4>
          <h1 class="little">{{productData.vegan}}</h1>
        </div>
      </div>
    </div>

      <!-- Alert quantity -->
  <!-- <div class="alert-qqty">
    <h5 *ngIf="productData.previousQuantity > 0" style= "color: #d84a8a;">
    You already have {{ productData.previousQuantity }}, maximum quantity is 15.
    </h5>
  </div>
   -->
  </div>

</div>



  <!-- Bottom Buttons -->
  <!-- <div class="bottomline flex-row">
      <select [(ngModel)]="chosenFlavor" [class.selector-hidden]="!productData.flavors">
        <option [ngValue]="null" class="font" disabled>Choose flavor...</option>
        <option
          *ngFor="let item of productData.flavors"
          class="font"
          [value]="item"
        >
          {{item}}
        </option>
      </select>
  <div class="last-part flex-row">
      <div class="quantity flex-row">
        <h4 class="small design" (click)="dec()">-</h4>
        <h4 class="small">{{itemqty}}</h4>
        <h4 class="small design" (click)="inc()">+</h4>
      </div>
 
    <div class="add-cart flex-row" [class.circle-cart]="closeAnimation" (click)="close(true)" [class.disabled]="isFlavorMandatory ? !this.chosenFlavor : false">
      <h4 class="small white">Add to Cart</h4>
      <h5 class="white top">$ {{productData.price * itemqty | number : '1.2-2'}}</h5>
    </div>
  </div>
  </div> -->
