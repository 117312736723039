import { Component, OnInit, EventEmitter, Inject, Injector, ɵbypassSanitizationTrustResourceUrl } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { MiskyProduct, MiskyCartItem } from 'src/app/types';
import { CONTAINER_DATA } from '../menu-daily/menu-daily.component';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import { DELIVERY_AREAS } from './delivery-areas';
import { Subscription } from 'rxjs';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-cart-view',
  templateUrl: './cart-view.component.html',
  styleUrls: ['./cart-view.component.scss'],
  providers: [HttpClient, DecimalPipe],
})
export class CartViewComponent implements OnInit {

  cartItems: MiskyCartItem[] = [];
  removedCartItems: MiskyCartItem[] = [];
  deliveryFee = 3;
  deliveryAreas = DELIVERY_AREAS;
  isDeliveryTime: boolean;
  DELIVERY_HOURS = [ 6, 15 ];

  isCheckOutHidden = false;
  isCheckOutConfirmed = false;

  orderCode=null;

  closeEmitter = new EventEmitter<void | boolean>();

  distribution = '';
  specialRequest = '';

  productData: MiskyProduct;

  checkoutForm = new FormGroup({
    emailAddress: new FormControl('', [Validators.pattern(`[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})`), Validators.required]),
    firstName: new FormControl('', [Validators.pattern(`([a-zA-Z',.-]+( [a-zA-Z',.-]+)*){2,30}`), Validators.required]),
    lastName: new FormControl('', [Validators.pattern(`([a-zA-Z',.-]+( [a-zA-Z',.-]+)*){2,30}`), Validators.required]),
    phoneNumber: new FormControl('', [Validators.pattern('^[0-9]{9,15}$'), Validators.required]),
    addressArea: new FormControl('', Validators.required),
    address1: new FormControl('', Validators.required),
    address2: new FormControl(''),
    addressExtra: new FormControl('')
  });
  formkey = 'xoqpzzaj';// mgepkadz
  emailOrder = true; // 🔴 true: sends to Formspree.io | false: only console
  isValid = false;

  constructor(
    @Inject(CONTAINER_DATA) public componentData: MiskyCartItem[],
    private http: HttpClient,
    private decimalPipe: DecimalPipe,
  ) { 

  }

  ngOnInit(): void {

    const deliveryMethod = localStorage.getItem('deliveryMethodOptionLS');
    if (deliveryMethod) {
      this.distribution = deliveryMethod;
    }

    this.checkoutForm.valueChanges.subscribe(() => {
      this.isValid = this.checkoutForm.valid;
    });

    this.cartItems = this.componentData;
  }

  isBetweenInterval(hoursInterval: number[]): boolean {
    const dateNow = new Date();
    const now = { hour: dateNow.getHours(), minutes: dateNow.getMinutes() };
    // const now = { hour: 10, minutes: 0 }; // for hard coding
    if (now.hour >= hoursInterval[0] && now.hour < hoursInterval[1]) {
      return true;
    } else {
      return false;
    }
  }

  get deliveryUnavailable(): boolean {
    return !(this.subtotalGreater20 && this.isBetweenInterval(this.DELIVERY_HOURS)) ? false : null;
  }

  get calculateSubtotal(): number {
    if (this.cartItems.length === 0) {
      return 0;
    }

    let value = 0;

    this.cartItems.map(i => {
      value += i.product.price * i.quantity;
    });

    return value;
  }

  close(): void {
    this.closeEmitter.emit();
  }

  remove(index: number): void {
    this.removedCartItems.push(this.cartItems[index]);

    this.cartItems.splice (index, 1);

    localStorage.setItem("cartLS", JSON.stringify(this.cartItems));
  }

  addAgainToCart(index: number): void {
    this.cartItems.push(this.removedCartItems[index]);

    this.removedCartItems.splice(index, 1);

    localStorage.setItem("cartLS", JSON.stringify(this.cartItems));
  }

  get subtotalGreater20(): boolean {
    return this.calculateSubtotal >= 20;
  }

  showCheckOut(): void {
    this.isCheckOutHidden = true;
  }
  closeCheckOut(): void{
    this.isCheckOutHidden = false;
  }

  get formControl(): { [key: string]: AbstractControl; } {
    return this.checkoutForm.controls;
  }

  submitForm(): Subscription | void {
    this.isValid = false;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type' : 'application/json'
      })
    };

    const parsedItems = this.cartItems.map(item => {
       return {
        quantity: item.quantity,
        product: item.product.name,
        flavor: item.flavor ?? 'no-flavor',
        category: item.product.category,
        price: item.product.price.toFixed(2)
      };
    });

    this.orderCode= this.generateOrder();

    const finalDeliveryFee = this.distribution === 'delivery' ? this.deliveryFee : 0;
    const formData = {
      code: this.orderCode,
      customerData: this.checkoutForm.value,
      items: parsedItems,
      method: this.distribution,
      special: this.specialRequest ?? 'no special request',
      subtotal: this.decimalPipe.transform(this.calculateSubtotal, '1.2-2'),
      deliveryFee: finalDeliveryFee.toFixed(2),
      total: this.calculateSubtotal + finalDeliveryFee
    };

    if (this.emailOrder) {
      return this.http
      .post('https://formspree.io/f/'+ this.formkey, formData, httpOptions)
      .subscribe(res => {
        this.isCheckOutConfirmed = true;
        console.log(res);
        this.closeEmitter.emit(true);
      });
    } else {
      this.isCheckOutConfirmed = true;
      console.log(formData);
      this.closeEmitter.emit(true);
      return;
    }
  }
  // increment item
  inc(itemIndex: number): void {
    if (this.cartItems[itemIndex].quantity >= 15) {
      this.cartItems[itemIndex].quantity = 15;
      alert('Want more than 15? Please, request a "Special Order" to be delivered upon availability 😉');
    } else {
      this.cartItems[itemIndex].quantity += 1;
    }
  }
  // decrement item
  dec(itemIndex: number): void {
  if (this.cartItems[itemIndex].quantity - 1 < 1){
    this.cartItems[itemIndex].quantity = 1;
  } else{
    this.cartItems[itemIndex].quantity -= 1;
    }
  }

  updateDistributionLS(){
    localStorage.setItem("deliveryMethodOptionLS", this.distribution);
    if (this.distribution=='delivery'){
      this.checkoutForm.get('addressArea').setValidators([Validators.required]);
      this.checkoutForm.get('address1').setValidators([Validators.required]);
      this.checkoutForm.updateValueAndValidity();
    } else{
      this.checkoutForm.get('addressArea').clearValidators();
      this.checkoutForm.get('address1').clearValidators();
      this.checkoutForm.updateValueAndValidity();
    }
  }

  //Order Generator
  generateOrder():string{
    let orderCode='';
    let month:number|string = (new Date()).getMonth() + 1;
    switch (month) {
      case 10:
        month ='A';
        break;
    
      case 11:
        month ='B';
        break;
    
      case 12:
        month ='C';
        break;
    
      default:
        break;
    }
      const day = (new Date()).getDate();

      const charsCollection = ['A','B','C','D','E','F','G','H','I','J','K','L','P',
      'Q','R','S','U','W','X','Y','1','2','3','4','5','6','7','8','9'];
      const selectedIndex1 = this.randomNumber(0,charsCollection.length -1);
      const selectedIndex2 = this.randomNumber(0,charsCollection.length -1);

      orderCode = (month+'') +  (day+'') + charsCollection[selectedIndex1] + charsCollection[selectedIndex2];
    return orderCode;
  }

  randomNumber(min, max): number { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

}
