<div class="contentfade-effect">
<!-- Back button -->
<div class="headline flex-row">
  <h4 class="boldpink" [routerLink]="['/menu']">< Back</h4>
</div>

<!-- content text -->
<div class="container">

<div class="content-text">
  <!-- Subtitulo -->
  <h3 class="subtitlepink">We Cater To You</h3>
  <!-- Contenido -->
  <p>No matter how big or small your party plans are, we will provide you with the best catering service for sweet and savory pastries. We attend all kinds of events and budgets.</p>
  <p class="space-p">Contact us for a custom proposal:</p>
  <!-- Contact info -->
  <div class="contact-container">
    <!-- <div class="contact-container flex-row">
      <img class="contacticon" src="assets/Icons/phone.svg">
      <p class="spacecontact">+1 402-742-0017</p>
    </div> -->
    <!-- Hidden until Client email confirmation -->
    <!-- <img class="contacticon" src="assets/Icons/email.svg">
    <a href="mailto:miskybakerylincoln@gmail.com" target="_blank">
      <p class="spacecontact">miskybakerylincoln@gmail.com</p>
    </a> -->
  </div>
  <div class="contact-container">
    <img class="contacticon" src="assets/Icons/messenger.svg">
    <a href="https://www.facebook.com/miskybakeryusa"  target="_blank">
      <p class="spacecontact">@miskybakeryusa</p>
    </a>
  </div>
</div>

<!-- Button accordeon/Column -->
<div class="column-buttons">

  <div class="cateringb flex-column" *ngFor="let item of catering; index as i">
    <div class="services" [class.active]="item.isVisible" (click)="toggleElement(i)">
      <div class="fila flex-row">
        <img class="special-img" [src]="'assets/' + item.image" [class.showing-image]="item.isVisible">
        <h1 class="smallpink"> {{ item.name }}
        </h1>
      </div>
      <h4 class="small" [class.showing-description]="item.isVisible">{{ item.description }}</h4>
    </div>
  </div>

</div>
</div>