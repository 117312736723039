<app-colum-image class="mobile-colimg" [images]="['tienda-about']"></app-colum-image>
<!-- Contenedor -->
<div class="content-text contentfade-effect">
    <!-- Primer subtitulo -->
    <h3 class="subtitlepink">Hi there! How can we Help?</h3>
    <!-- Primer contenido -->
    <p>We become a better company when we hear from you! Just leave us a message, and we’ll get in touch shortly.</p>
    <!-- Segundo subtitulo -->
    <p class="p3mediumpink">Come visit</p>
    <p>Located at 505 N 27th St, Lincoln - Nebraska (U.S.) </p>
<!-- AIzaSyB__YTros2VkW5kTP87atSP9PMxo7tjWoY -->
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3019.4252085635085!2d-96.68503848459179!3d40.81862307932051!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8796be9301483b21%3A0x577f515687983284!2sMisky%20Bakery!5e0!3m2!1ses-419!2seg!4v1596105125765!5m2!1ses-419!2seg"
        class="map-google"
      
        frameborder="0"
        style="border:0;"
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
    ></iframe>

    <!-- <h5>Get directions</h5> -->
    <!-- tercer subtitulo -->
    <p class="p3mediumpink">Opening hours</p>
        <p class="spacehours"> Opening daily from 6AM to 8PM and Sundays starting at 730AM.</p>
        <p class="spacehours"> Our delivery service is currently unavailable.</p> 

    <!-- cuarto subtitulo -->
    <!-- <p class="p3mediumpink" >Get in touch</p> -->
    <!-- <div class="contact-container flex-row">
        <img class="contacticon" src="assets/Icons/phone.svg">
        <p class="spacephone">+1 402-742-0017</p>
    </div> -->
    <!-- Hidden until Client new email confirmation -->
    <!-- <div class="contact-container flex-row">
        <img class="contacticon" src="assets/Icons/email.svg">
        <a href="mailto:miskybakerylincoln@gmail.com" target="_blank">
            <p class="spacecontact">miskybakerylincoln@gmail.com</p>
        </a>
    </div> -->
    <!-- <div class="contact-container flex-row">
        <img class="contacticon" src="assets/Icons/messenger.svg">
        <a href="https://www.facebook.com/miskybakeryusa"  target="_blank">
            <p class="spacecontact">@miskybakeryusa</p>
        </a>
    </div> -->
</div>
    <app-colum-image class="aside-colimg" [images]="['tienda-about']"></app-colum-image>
