<!-- Background -->
<div class="content flex-column">
    <!-- Body -->
    <div class="home-effect">
        <h2 class="textspace">We Are</h2>
        <div class="flex-row writting-text">
        <h2 class="cursive">{{typewriterDisplay}}</h2>
        <h1 class="white blinking-cursor">|</h1>
        </div>
    </div>

    <div class="product-image">
        <img class="product" [src]="'assets/' + productShown + '-landscape.png'">
    </div>
    <div class="second-text flex-row">
        <h3 class="subtitle spacing">Don't wait more,</h3>
        <h3 class="subtitle flex-row" [routerLink]="['/order']">
            <span *ngFor="let item of hoverTextArray" class="letter" [class.space]="item === ' '">
                {{item}}
            </span>
        </h3>
    </div>
        <!-- Ad MENU for mobile -->
    <div class="menu-buttons" [class.menu-ad-show]="MenuAdShown">
        <div class="ad" [routerLink]="['/menu/daily']">
            <img class="menu-img" src="assets/Icons/daily-menuhome.svg">
            <h3 class="menu-text">Daily</h3>
        </div>
        <div class="ad" [routerLink]="['/menu/cakes']">
            <img class="menu-img" src="assets/Icons/cakes-menuhome.svg">
            <h3 class="menu-text">Cakes</h3>
        </div>
        <div class="ad" [routerLink]="['/menu/special']">
            <img class="menu-img" src="assets/Icons/special-menuhome.svg">
            <h3 class="menu-text">Special</h3>
        </div>
    </div>
    
        <!-- Bottom -->
    <div class="last-part flex-column">
        <div class="social-icons">
            <a href="https://www.facebook.com/miskybakeryusa"  target="_blank">
                <img class="Fb" style="display:inline" src="assets/Icons/Facebook-pink.svg">
            </a>
            <a href="https://www.instagram.com/sweetmisky"  target="_blank">
                <img class="Insta" style="display:inline" src="assets/Icons/Instagram-pink.svg">
            </a>
        </div>    
        <div class="footer flex-row">
            <h5 class="bottom">Misky Bakery © 2020</h5> 
            <h5 class="bottom bar"> |</h5>
            <h5 class="bottom site-info" (click)="openAboutSite()"> About this Website </h5>
            <h5 class="bottom bar"> |</h5>
            <div class="flex-row">
            <h5 class="bottom"> Developed by LD</h5> 
            </div>
        </div>
    </div>

</div>






