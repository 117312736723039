import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FacebookService, InitParams } from 'ngx-facebook';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'miskyapp1';

  constructor(
    public router: Router,
    private facebookService: FacebookService
  ) { }

  ngOnInit(): void {
    this.initFacebookService();
  }

  initFacebookService(): void {
    const initParams: InitParams = { xfbml: true, version: 'v8.0' };
    this.facebookService.init(initParams);
  }

  public get getRoute(): string {
    return this.router.url;
  }
}
