import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  lillyObjects = [
    {
      name: 'Cliff F.',
      review: `I wish there were more stars to give this place, from the great deserts to burritos and tamales. You'll find a bit of everything in this place. I highly recommend, stopping by. The people are friendly and it just smells so good!!!`,
      date: 'February 2020'
    }, {
      name: 'Violet C.',
      review: 'I like to stop here for something sweet after work, always has far more interesting things than most of the other bakeries',
      date: 'February 2020'
    }, {
      name: 'Boagnerges B.',
      review: 'So far, the best burritos I have had in Lincoln, I still need to go to more places tho',
      date: 'Jan 2020'
    }, {
      name: 'Cindy P.',
      review: 'Always love going here when I want something sweet. Workers are super friendly',
      date: 'January 2020'
    }, {
      name: 'Kelly S.',
      review: 'Low prices, there’s a variety of bread and fresh cooked food in case you want to grab a bite. Location is great and parking',
      date: 'August 2019'
    }, {
      name: 'Jonathan R.',
      review: 'Cheap and delicious pan dulce made by friendly, wonderful people',
      date: 'August 2019'
    }, {
      name: 'Kyle M.',
      review: 'Love the food and pricing!',
      date: 'July 2019'
    }, {
      name: 'Sabra R.',
      review: 'Great staff and cheap place for pan dulce',
      date: 'April 2019'
    }, {
      name: 'Shannon F.',
      review: 'Everything is amazing! Very friendly staff',
      date: 'April 2019'
    }, {
      name: 'Stephanie H.',
      review: 'This place is like bakery heaven! Everything is so good. Try the cream cheese jalapenio bread. Yum!',
      date: 'April 2019'
    }, {
      name: 'Julie R.',
      review: 'Fresh and authentic. Chicken empanadas are sooooo delicious!',
      date: 'January 2019'
    }, {
      name: 'Melissa M.',
      review: 'Wow. This place is great!! Not the typical super sweet American cakes and pies that I’m used to; and that’s a good thing. Tres leches cake is amazing. The bread pudding that I had was stellar- and tasted like bananas. Honestly, I don’t even like bananas. But this was sooooo good. The people who work here are also VERY nice. Love it!',
      date: 'November 2018'
    }, {
      name: 'Morgan L. ',
      review: 'Delicious baked goods! The selection of sweets is vast and impressive. They also sell savory foods, I tried a jalapenio and cream cheese stuffed bread. It was excellent. The lady recommended I come earlier in the day for burritos and I plan to do so! She was very kind and friendly, I look forward to more food and conversation soon',
      date: 'June 2018'
    }, {
      name: 'Vanessa D.',
      review: 'Delicious Mexican bread and great service',
      date: 'April 2018'
    }, {
      name: 'Gopi N.',
      review: 'I ordered a three milk & Black Forest for my kids birthdays yesterday. Both cakes tasted very good. Lula was very professional',
      date: 'December 2017'
    }, {
      name: 'Brandee B.',
      review: 'Love this bakery. Everybody is very pleasant and helpful. I like to go there to get donuts and cookies. Everything is priced very well. I recently ordered a couple dozen cinnamon rolls, I bought them for a party I was having to go with the chili I made. They were a hit! I had to hide a couple of them from my guests or they would have all been gone! I am so happy I decided to just order some! They were really good and looked awesome, thanks!',
      date: 'July 2016'
    }
  ];
  showingIndex = 0;

  constructor() { }

  ngOnInit(): void {
    setInterval(() => {
      this.showNext();
    }, 6000);
  }

  showPreview(): void {
    if (this.showingIndex > 0) {
      this.showingIndex--;
    } else {
      this.showingIndex = this.lillyObjects.length - 1;
    }
  }

  showNext(): void {
    if (this.showingIndex < (this.lillyObjects.length - 1)) {
      this.showingIndex++;
    } else {
      this.showingIndex = 0;
    }
  }
}
