<!-- Cards -->
<div class="card">
  <div class="imageinfo flex-column">
    <img class="productimg" [src]="'assets/Menu/' + data.image">
  </div>
  <div class="contentinfo flex-column">
    <h4 class="bold">{{ data.name }}</h4>
    <p class="p4small">{{ data.description }}</p>
    <h4 class="boldsmall">$ {{ data.price | number: '1.2-2' }}</h4>
    <!-- <h4 class="button-more">More</h4> -->
  </div>
</div>
