<app-colum-image class="mobile-colimg" [images]="['tienda-about', 'conferencia-about', 'segundoperfil-about']"></app-colum-image>

<!-- Contenedor -->
<div class="content-text contentfade-effect">
    <!-- PRIMERO -->
    <div>
    <!-- Primer subtitulo -->
    <h3 class="subtitlepink">Our beginning</h3>
    <!-- Primer contenido -->
    <p>
    My name is Lourdez Gonzales. I am from Bolivia and have been here in the U.S. since 2001. I’m the owner of 
        <span class="highlight">Misky’s bakery</span> 
    which is an artisan Latino bakery in Lincoln Nebraska. With the help of my family and a private investor from my church, I purchased  the former Pan Dulce bakery time ago. 
    </p>
    <p>
    We make Breads, Latino Sweet Breads, cakes, croissants, cookies and hot items for carry out such as salteñas (saldenias), burritos and tamales.  Some of our breads are so popular, we bake them several times a day! 
    </p>
    <p>
    As of today, I have a dedicated staff of seven Latino women from Central and South America. I do have a couple of positons that I use to help members of the community when they are in need.
    </p>
    <p>
    There were many days during the first few months that my limited staff and I prayed that God Will Provide.  We did the best we could and slowly and steadily improved. 
        <span class="highlight">Day by Day</span>
    .Those first few months evolved into an improved  process and stability.  Although improvement is necessary, we continue to improve. We will get there someday.
    </p>
    </div>

    <!-- SEGUNDO -->
    <div>
    <!-- Segundo subtitulo -->
    <div class="spacehead">
    <h3 class="subtitlepink">You helped us GROW</h3>
    </div>
    <!-- Segundo contenido -->
    <p>
    We were invited to the  
        <span class="highlight">5th Smithsonian</span>
        <span class="highlight tab-space">Food History</span>
        <span class="highlight">Weekend (2019),</span>
    to speak about the topic:  
        <span class="italic">"Empowering </span> 
        <span class="italic tab-space"> communities </span> 
        <span class="italic"> through food". </span> 
    I was able to share my own story, about the obstacles, challenges and opportunities I faced and how culinary knowledge and skills opened a path to economic well-being, allowing us to create sustainable livelihoods not only for ourselves, but also for our communities in the U.S.
    </p>
    <p>
        I moved to the US 20 years ago, I had to work 2 full time jobs and sometimes one additional part time job to make enough living for my 3 children. Sooner the situation got complicated, so I started to seek a new more “humane” way to make a living. That’s when I started to work in Pan Dulce bakery and the begining of 
        <span class="highlight"> Misky Bakery</span>
        .
    </p> 
    <p>
        My bakery not only gives me profit and provides delicious bread but it also gives me the opportunity to help people, mainly women, that are in the same situation I was 6 years ago or that have any other problems. I do this as often as the opportunity presents itself.  
    </p>
    <p>
        I participate in local business groups and I embrace helping local organizations in their fundraisers.  Providing Tamales and such at a discount rate, giving them inventory and allowing them to pay me after the event is over and taking the unsold items back.  This provides good will and improves awareness of my bakery.
    </p>
    <p>
        I want to be known as a good and upstanding woman in my community. To do the right thing, provide good and wholesome guidance to people in need.
    </p>
    </div>

    <!-- TERCERO -->
    <div>
    <!-- tercer subtitulo -->
    <div class="spacehead">
        <h3 class="subtitlepink">We are Family</h3>
    </div>
    
    <!-- Tercer contenido -->
    <p>
        Our Misky clientele  is more than just Latinos, but customers from all nationalities and cultures.
    </p>
    <p>
        Food is the common denominator for all people of the world.. It has no race, no prejudices, abet there are preferences for types of food.  I will happily break bread with anyone who is willing to break bread with me. I will teach them about my culture and I hope that they will teach me about their culture.  We will learn about each other’s culture and grow a mutual respect for those of different cultures.
    </p>
    </div>

    <!-- CUARTO -->
    <div>
    <!-- cuarto subtitulo -->
    <div class="spacehead">
    <h3 class="subtitlepink">Our customers Love</h3>
    </div>
    
    <!-- Review container -->
    <div class="review">
      <!-- <div class="review" *ngFor="let item of lillyObjects; index as i"> -->
        <div class="containertext flex-row">
          <img class="arrowleft" src="assets/Icons/arrowleft.svg" (click)="showPreview()">
          <p class="p2italic review-effect">“{{ lillyObjects[showingIndex].review }}”</p>
          <img class="arrowright" src="assets/Icons/arrowright.svg" (click)="showNext()">
        </div>
        <div class="containerbottom flex-row">
          <div class="reviewer flex-row">
             <h3 class="smallboldpink review-effect">
                 <a href="https://www.restaurantji.com/ne/lincoln/misky-bakery-/comments/" target="_blank">
                    {{ lillyObjects[showingIndex].name }}
                </a>
            </h3>
            <h5 class="bold review-effect"> ({{lillyObjects[showingIndex].date }})</h5>
          </div>
          <div class="stars flex-row">
            <img class="star1" src="assets/Icons/star.svg">
            <img class="star2" src="assets/Icons/star.svg">
            <img class="star3" src="assets/Icons/star.svg">
            <img class="star4" src="assets/Icons/star.svg">
            <img class="star5" src="assets/Icons/star.svg">
          </div>
        </div>
      <!-- </div> -->
    </div>
    
    <!-- final -->
    <h5 class="liitpink last">Thanks for your support, we will 
        <span class="highlightitalic">bake</span>
        more memories together!</h5>
        
    </div>
</div>

<!-- <div> -->
    <app-colum-image class="aside-colimg" [images]="['tienda-about', 'conferencia-about', 'segundoperfil-about']"></app-colum-image>
<!-- </div> -->
   
