<!-- headline -->
<div  class="headline flex-row">
  <div class="close-button" (click)="close()">
    <img class="close" src="assets/Icons/closeb-pink-1view.svg">
    <img class="fill" src="assets/Icons/closeb-pink-2view.svg">
  </div>
  <h3 class="smallboldpink title"> ABOUT THIS WEBSITE </h3>
</div>
<!-- content -->
<div class="container-info flex-column contentfade-effect">
<div class="space">
  <h4 class="small">
    Misky Bakery is the sole owner of the information collected on miskybakery.com. By ordering our products and using our services, you are consenting to the data collection described in this Privacy Policy. We reserve the right to modify our Policy and invite you to consult this Privacy Policy from time to time in order to familiarize yourself with any changes. We will post any new Privacy Policy on our Website. 
  </h4>
</div>
<div class="space">
  <h3 class="smallboldpink">Registration</h3>
  <h4 class="small">
    We don't request/require you to create an account nor register on our website. However, the website uses Facebook Messenger as a quick communication channel for general or order inquiries, therefore prior login on Facebook might be necessary.
  </h4>
</div>
<div class="space">
  <h4 class="boldpink">Personal Information</h4>
  <h4 class="small">
    We use the name, phone number, email address, and shipping address information to communicate with you about your order. When you place your order, we will confirm the request through call or email. 
    Our website is not yet enabled for online payments, so financial information will not be requested.
  </h4>
  <h4 class="small">
    On store pickup, we accept cash or card payments (Visa, MasterCard, American Express, and Discover). For delivery orders, customers can pay the delivery person in cash upon delivery or by phone while on the order confirmation call.
  </h4>
</div>
<div class="space">
  <h4 class="boldpink">Minors and Children</h4>
  <h4 class="small">
    Minors and children (persons under the age of 18) are not encouraged to use the Site unsupervised and we ask that minors and children do not submit any personal information to us. By using this website, you warrant that you are 18 years of age or older.
  </h4>
</div>
<div class="space">
  <h3 class="smallboldpink">Communications</h3>
  <h4 class="small">
    Our official ways of communication to customers are either by phone, email, or Facebook Messenger, in order to respond to your inquiries and to provide the services you request. We appreciate your feedback. Feel free to contact us by email.
  </h4>
</div>
<div class="space">
  <h4 class="boldpink">Special Offers and Updates</h4>
  <h4 class="small">
    Special offers and updates about our products and services will be published on our website and social accounts. The product prices displayed on the Site are subject to change at any time with or without notice.
  </h4>
</div>
<div class="space">
  <h3 class="smallboldpink">Use of this website</h3>
  <h4 class="small">
    You may access, copy, download or print the content of our Site solely for your personal, non-commercial use, provided you do not modify or delete any copyright or trademark contained.
  </h4>
</div>
<h5 class="bottom">Misky Bakery 2021. Version {{version}}. All rights reserved.</h5>
</div>
