import { Component, EventEmitter, OnInit } from '@angular/core';
import { version } from '../../../../package.json';
@Component({
  selector: 'app-about-site',
  templateUrl: './about-site.component.html',
  styleUrls: ['./about-site.component.scss']
})
export class AboutSiteComponent implements OnInit {

  closeEmitter = new EventEmitter<void>();
  version = version;
  constructor() { }

  ngOnInit(): void {
  }

  close(): void {
    this.closeEmitter.emit();
  }
}
