<!-- Headline -->
<div class="headline flex-row">
  <h4 class="boldpink" [routerLink]="['/menu']">< Back</h4>
  <h3 class="subtitlepink title">Daily Misky</h3>

  <!-- <div class="mobile-cart">
    <button class="box-cart flex-row" (click)="openCart()">
      <img class="cart-mob" [class.cart-move]="cartAnimation" src="assets/Icons/cart-pink.svg">
      <span class="item-display-mobile" [class.bubble-appear]="cartAnimation" *ngIf="cart.length > 0">{{cart.length}}</span>
    </button>
  </div> -->

</div>
<div class="container">
  <!-- MOBILE MENU SELECTION -->
  <div class="mobile-selection flex-row">
      
        <h4 class="bold box" [class.select-selected]="activeFilter === 'bread'" (click)="activeFilter = 'bread'">Bread</h4>
        <h4 class="bold box" [class.select-selected]="activeFilter === 'cookies'" (click)="activeFilter = 'cookies'">Cookies</h4>
        <h4 class="bold box" [class.select-selected]="activeFilter === 'sweetbread'" (click)="activeFilter = 'sweetbread'">Sweet Bread</h4>
        <h4 class="bold box" [class.select-selected]="activeFilter === 'cup'" (click)="activeFilter = 'cup'">Desserts in a cup</h4>
        <h4 class="bold box" [class.select-selected]="activeFilter === 'savory'" (click)="activeFilter = 'savory'">Savory</h4>
        <h4 class="bold box" [class.select-selected]="activeFilter === 'cakes'" (click)="activeFilter = 'cakes'">Daily Cakes</h4>
      
    </div>

  <!-- Column MENU SELECTION -->
  <div class="aside-selection flex-column">
    <!-- Top -->
      <h4 class="bold select" [class.select-selected]="activeFilter === 'bread'" (click)="activeFilter = 'bread'">Bread</h4>
      <h4 class="bold select" [class.select-selected]="activeFilter === 'cookies'" (click)="activeFilter = 'cookies'">Cookies</h4>
      <h4 class="bold select" [class.select-selected]="activeFilter === 'sweetbread'" (click)="activeFilter = 'sweetbread'">Sweet Bread</h4>
      <h4 class="bold select" [class.select-selected]="activeFilter === 'cup'" (click)="activeFilter = 'cup'">Desserts in a cup</h4>
      <h4 class="bold select" [class.select-selected]="activeFilter === 'savory'" (click)="activeFilter = 'savory'">Savory</h4>

      <h4 class="bold select select-other" [class.select-selected]="activeFilter === 'cakes'" (click)="activeFilter = 'cakes'">Daily Cakes</h4>
 
    <!-- Bottom -->
    <!-- <button class="cart flex-row" (click)="openCart()">
      <h4 class="white">My Order | </h4>
      <img class="bshoppingcart" [class.cart-move]="cartAnimation" src="assets/Icons/cart.svg">
      <span class="item-display" [class.bubble-appear]="cartAnimation" *ngIf="cart.length > 0">{{cart.length}}</span>
    </button> -->
    <!-- <a [href]="'http://'+ host + '/assets/MiskyBakery(DailyMenu).pdf'" download="MiskyBakery(DailyMenu).pdf">
      <h4 class="subpinklight">Download Menu PDF</h4>
    </a> -->
  </div>

<!-- Product Cards -->
  <div class="cards-container flex-row">
    <app-menu-card [data]="item" *ngFor="let item of filteredProducts; index as i"></app-menu-card>
    <div *ngIf="products.length" class="ghost-product"></div>
  </div>


</div>
