<!-- Column-Image -->
<div class="column-image-wrapper flex-row">
    <div
        *ngFor="let item of images; index as i"
        class="column-image"
        [style.background-image]="'url(assets/' + item + '.png)'"
        [class.animate]="images.length > 1"
        [class.animate5]="images.length == 5"
    ></div>
</div>
<div class="sm-icons flex-row">
    <a href="https://www.facebook.com/miskybakeryusa"  target="_blank">
        <img class="Fb" src="assets/Icons/Facebook.svg">
    </a>
    <a href="https://www.instagram.com/sweetmisky"  target="_blank">
        <img class="Insta" src="assets/Icons/Instagram.svg">
    </a>
</div>