import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-special',
  templateUrl: './menu-special.component.html',
  styleUrls: ['./menu-special.component.scss']
})
export class MenuSpecialComponent implements OnInit {

  catering = [
    {
      image: 'Icons/Corporate.svg',
      name: 'Corporate catering',
      description: `We provide freshly baked desserts for a variety of corporate functions, from breakfast meetings and afternoon coffee breaks to birthday celebrations and employee recognition events.`,
      isVisible: false
    },
    {
      image: 'Icons/Event.svg',
      name: 'Catering for events',
      description: `Celebrate life’s sweetest moments with Misky Bakery! From baby showers, weddings to birthdays, graduations, or family reunions. We personalized cakes and pastries for your next event.`,
      isVisible: false
    },
    {
      image: 'Icons/Fundraisers.svg',
      name: 'Fundraisers',
      description: `We love to help local organizations in their fundraisers, which is why we offer special discounts, we will provide you with the inventory and allowing the payment once the event is over.`,
      isVisible: false
    }
  ];
  
  constructor() { }

  ngOnInit(): void {
  }

  toggleElement(index: number): void {
    this.catering.map ((item, i) => item.isVisible = i === index ? !this.catering[index].isVisible : false );
  }
}
