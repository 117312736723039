import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
  
  DELIVERY_HOURS = [ 6, 15 ];

  constructor(
    private route:Router
  ) {
  }

   saveLS(value:'pickup' | 'delivery'){
    localStorage.setItem("deliveryMethodOptionLS", value);
    this.route.navigate(['/menu/daily']);
   }

  ngOnInit(): void {
  }

  isBetweenInterval(hoursInterval: number[]): boolean {
    const dateNow = new Date();
    const now = { hour: dateNow.getHours(), minutes: dateNow.getMinutes() };
    // const now = { hour: 10, minutes: 0 }; // for hard coding
    if (now.hour >= hoursInterval[0] && now.hour < hoursInterval[1]) {
      return true;
    } else {
      return false;
    }
  }

  get deliveryUnavailable(): boolean {
    return !(this.isBetweenInterval(this.DELIVERY_HOURS)) ? false : null;
  }
}
