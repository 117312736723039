import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {

MobileMenuShown = false;

  constructor(
    public router: Router
  ) { }

  public get getRoute(): string {
    return this.router.url;
  }

}
