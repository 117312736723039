import { Component, OnInit, Input } from '@angular/core';
import { MiskyProduct } from '../../types';

@Component({
  selector: 'app-menu-card',
  templateUrl: './menu-card.component.html',
  styleUrls: ['./menu-card.component.scss']
})
export class MenuCardComponent implements OnInit {

  @Input() data: MiskyProduct;

  constructor() { }

  ngOnInit(): void {
  }

}
