import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { MenuComponent } from './menu/menu.component';
import { MenuHomeComponent } from './menu/menu-home/menu-home.component';
import { MenuDailyComponent } from './menu/menu-daily/menu-daily.component';
import { MenuCakesComponent } from './menu/menu-cakes/menu-cakes.component';
import { MenuSpecialComponent } from './menu/menu-special/menu-special.component';
import { OrderComponent } from './order/order.component';
import { FaqComponent } from './faq/faq.component';
import { ContactComponent } from './contact/contact.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'menu',
    component: MenuComponent,
    children: [
      { path: '', component: MenuHomeComponent },
      { path: 'daily', component: MenuDailyComponent },
      { path: 'cakes', component: MenuCakesComponent },
      { path: 'special', component: MenuSpecialComponent },
    ]
  },
  // { path: 'order', component: OrderComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'contact', component: ContactComponent },
  // { path: 'hero/:id',      component: HeroDetailComponent },
  // {
  //   path: 'heroes',
  //   component: HeroListComponent,
  //   data: { title: 'Heroes List' }
  // },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  // { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
