import { Component, OnInit, Input } from '@angular/core';
import { MiskyGallery } from '../../types';

@Component({
  selector: 'app-gallerycard',
  templateUrl: './gallerycard.component.html',
  styleUrls: ['./gallerycard.component.scss']
})
export class GalleryCardComponent implements OnInit {

  @Input() data: MiskyGallery;

  constructor() { }

  ngOnInit(): void {
  }

}
