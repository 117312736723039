<!-- NavBar -->
<div class="toolbar" role="banner">
    <div class="toolbar__left" [class.linewhite]="getRoute == '/home'" >
      <div class="toolbar-button" [routerLink]="['/home']" routerLinkActive="activo" >
        <h1 [class.initial]="getRoute == '/home'">HOME</h1>
        <div class="toolbar-button--selected"[class.box]="getRoute == '/home'"></div>
      </div>
      <div class="toolbar-button"[routerLink]="['/about']" routerLinkActive="activo">
        <h1 [class.shadow]="getRoute == '/home'"> ABOUT</h1>
        <div class="toolbar-button--selected" [class.box]="getRoute == '/home'"></div>
      </div>
      <div class="toolbar-button" [routerLink]="['/menu']" routerLinkActive="activo">
        <h1 [class.shadow]="getRoute == '/home'"> MENU </h1>
        <div class="toolbar-button--selected" [class.box]="getRoute == '/home'"></div>
      </div>
    </div>
    <img *ngIf="getRoute == '/home'" class="logo-big" src="assets/Icons/miskyoriginal.svg">
    <img *ngIf="getRoute !== '/home'" class="logo-normal" src="assets/Icons/miskyoriginal.svg">
    <div class="toolbar__right" [class.linewhite]="getRoute == '/home'" >
      <!-- <div class="toolbar-button" [routerLink]="['/order']" routerLinkActive="activo">
        <h1 [class.shadow]="getRoute == '/home'"> ORDER</h1>
        <div class="toolbar-button--selected"[class.box]="getRoute == '/home'"></div>
      </div> -->
      <div class="toolbar-button" [routerLink]="['/faq']" routerLinkActive="activo">
        <h1 [class.shadow]="getRoute == '/home'"> FAQ</h1>
        <div class="toolbar-button--selected"[class.box]="getRoute == '/home'"></div>
      </div>
      <div class="toolbar-button" [routerLink]="['/contact']" routerLinkActive="activo">
        <h1 [class.shadow]="getRoute == '/home'"> CONTACT</h1>
        <div class="toolbar-button--selected"[class.box]="getRoute == '/home'"></div>
      </div>
    </div>
</div>

<!-- MOBILE VIEW -->
<div class="toolbar-mobile flex-row">
  <div class="headline flex-row">
  <!-- LEFT: Menu button-->
  <div class="close-effect" (click)="MobileMenuShown = !MobileMenuShown">
    <img [class.close-effect-open]="MobileMenuShown" src="assets/Icons/menubarra-home.svg">
    <img [class.close-effect-closing]="!MobileMenuShown" src="assets/Icons/closeb-pink-1view.svg">
  </div>
  <!-- CENTER: LOGO-->
    <img class="logo-mobile" src="assets/Icons/miskyoriginal.svg"  [routerLink]="['/home']">
  <!-- RIGHT: CONTACT SHORTCUT-->
  <div class="navbar-right">
    <!-- <a href="tel:402 742-0017">
      <img class="icons" src="assets/Icons/phoneb-home.svg">
    </a> -->
    <a href="https://www.google.com/maps/place/Misky+Bakery,+505+N+27th+St,+Lincoln,+NE+68503,+Estados+Unidos/@40.8186231,-96.6828498,15z/data=!4m6!1m3!3m2!1s0x8796be9301483b21:0x577f515687983284!2sMisky+Bakery,+505+N+27th+St,+Lincoln,+NE+68503,+Estados+Unidos!3m1!1s0x8796be9301483b21:0x577f515687983284" target="_blank">
      <img class="icons" src="assets/Icons/locationb-home.svg">
    </a>
  </div>
</div>


  <div class="mobile-menu" [class.mobile-menu-show]="MobileMenuShown">
    <div class="content-buttons flex-column">
      <h1 class="text"  [routerLink]="['/home']" (click)="MobileMenuShown = !MobileMenuShown">HOME</h1>
      <h1 class="text"  [routerLink]="['/about']" (click)="MobileMenuShown = !MobileMenuShown">ABOUT</h1>
      <h1 class="text"  [routerLink]="['/menu']" (click)="MobileMenuShown = !MobileMenuShown">MENU</h1>
      <!-- <h1 class="text"  [routerLink]="['/order']" (click)="MobileMenuShown = !MobileMenuShown">ORDER</h1> -->
      <h1 class="text"  [routerLink]="['/faq']" (click)="MobileMenuShown = !MobileMenuShown">FAQ</h1>
      <h1 class="text"  [routerLink]="['/contact']" (click)="MobileMenuShown = !MobileMenuShown">CONTACT</h1>
    </div>
    <img class="img-navbar" src="assets/navbar-phone.png">
  </div>
</div>
  