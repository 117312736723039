import { Component, OnInit, InjectionToken, Injector } from '@angular/core';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { GalleryViewComponent } from '../gallery-view/gallery-view.component';
import { MiskyCakesCategory, MiskyGallery } from '../../types';

export const CONTAINER_DATA = new InjectionToken<string>('CONTAINER_DATA');

@Component({
  selector: 'app-menu-cakes',
  templateUrl: './menu-cakes.component.html',
  styleUrls: ['./menu-cakes.component.scss'],
  providers: [ Overlay ]
})
export class MenuCakesComponent implements OnInit {

  sizeAndShapeProducts: MiskyCakesCategory[] = [
    {
      name: 'Heart Shape',
      cakes: [
        {
          image: 'heart-6inch.svg',
          name: '6 inch',
          description: 'For 6 to 8 servings',
        }
      ]
    }, {
      name: 'Round Shape',
      cakes: [
        {
          image: 'round-6inch.svg',
          name: '6 inch',
          description: 'For 6 to 8 servings'
        }, {
          image: 'round-8inch.svg',
          name: '8 inch',
          description: 'For 12 to 18 servings'
        }, {
          image: 'round-10inch.svg',
          name: '10 inch',
          description: 'For 25 to 30 servings'
        }
      ]
    },
    {
      name: 'Square Shape',
      cakes: [
        {
          image: 'square-8x6.svg',
          name: '8x6 inch',
          description: 'For 6 to 8 servings'
        },
        {
          image: 'square-10x14.svg',
          name: '10x14 inch',
          description: 'For 12 to 18 servings'
        },
        {
          image: 'square-14x19.svg',
          name: '14x19 inch',
          description: 'For 25 to 30 servings'
        },
        {
          image: 'square-fullsheet.svg',
          name: 'Full sheet',
          description: 'For 50 to 60 servings'
        }
      ]
    }
  ];

  flavorProducts: MiskyCakesCategory[] = [
    {
      name: 'Sponge Flavor',
      cakes: [
        {
          image: 'round-8inch.svg',
          name: 'Vanilla',
          description: 'A firm yet tender dough with a pure vanilla flavor',
        },
        {
          image: 'chocolate.svg',
          name: 'Chocolate',
          description: 'Chocolate lovers choice! Moist and pure of flavor',
        },
      ]
    }, {
      name: 'Filling & Frosting',
      cakes: [
        {
          image: 'frosting-vanilla.svg',
          name: 'Vanilla',
          description: 'Light and fluffy cream with vanilla flavor',
        },
        {
          image: 'frosting-strawberry.svg',
          name: 'Strawberry',
          description: 'Light and fluffy cream with fresh strawberries',
        },
        {
          image: 'frosting-pineapple.svg',
          name: 'Pineapple',
          description: 'Light and fluffy cream with chopped pinneaples',
        },
        {
          image: 'frosting-banana.svg',
          name: 'Banana',
          description: 'Light and fluffy cream with smashed bananas',
        },
      ]
    }, {
      name: 'Styles',
      cakes: [
        {
          image: 'naked.svg',
          name: 'Naked',
          description: 'Cream only between the layers and on top',
        },
        {
          image: 'seminaked.svg',
          name: 'Semi-Naked',
          description: 'A light layer of cream of your choice, covering the sides',
        },
        {
          image: 'covered.svg',
          name: 'Fully Covered',
          description: 'Sides completely covered with the cream of your choice',
        },
      ]
    }
  ];

  specialProducts: MiskyCakesCategory[] = [
    {
      name: 'Sponge Flavor',
      cakes: [
        {
          image: 'redvelvet.svg',
          name: 'Red Velvet',
          description: 'Moist, reddish colored cake',
          additionalPrice: 5.00
        },
        {
          image: 'coconout.svg',
          name: 'Coconut',
          description: 'Moist, with a touch of coconut',
          additionalPrice: 5.00
        },
        {
          image: 'blackforest.svg',
          name: 'Black Forest',
          description: 'Rich chocolate flavor sponge',
          additionalPrice: 8.00
        },
        {
          image: 'germanchocolate.svg',
          name: 'German Chocolate',
          description: 'Moist, with a lighter chocolate flavor',
          additionalPrice: 5.00
        },
      ]
    }, {
      name: 'Toppings',
      cakes: [
        {
          image: 'freshfruit.svg',
          name: 'Fresh Fruit',
          description: 'Seasonal fruit of your choice. Price may vary with the cake size.',
        },
        {
          image: 'nuts.svg',
          name: 'Nuts',
          description: 'Coated with toasted nuts. Price may vary with the cake size.',
        },
        {
          image: 'caramel.svg',
          name: 'Caramel',
          description: 'Light and salted caramel layer. Price may vary with the cake size.',
        },
      ]
    }
  ];

  galleryProducts: MiskyGallery[] = [
    {
      image: 'cake-doble1.png',
      description: 'Double layer 9’’ and 6’’',
    },
    {
      image: 'cake-doble2.png',
      description: 'Double layer 9’’ and 6’’',
    },
    {
      image: 'cake-doble3.png',
      description: 'Double layer 9’’ and 6’’',
    },
    {
      image: 'cake-doble4.png',
      description: 'Double layer 9’’ and 6’’',
    },
    {
      image: 'cake-doble5.png',
      description: 'Double layer 12’’ and 9’’',
    },
    {
      image: 'cake-doble6.png',
      description: 'Double layer 9’’ and 6’’',
    },
    {
      image: 'cake-round1.png',
      description: 'Single layer 9’’',
    },
    {
      image: 'cake-round2.png',
      description: 'Single layer 9’’',
    },
    {
      image: 'cake-round3.png',
      description: 'Single layer 9’’',
    },
    {
      image: 'cake-round4.png',
      description: 'Single layer 9’’',
    },
    {
      image: 'cake-round5.png',
      description: 'Single layer 9’’',
    },
    {
      image: 'cake-round6.png',
      description: 'Single layer 6’’',
    },
    {
      image: 'cake-round7.png',
      description: 'Single layer 9’’',
    },
    {
      image: 'cake-round8.png',
      description: 'Single layer 9’’',
    },
    {
      image: 'cake-round9.png',
      description: 'Single layer 6’’',
    },
       {
      image: 'cake-special1.png',
      description: 'Single layer 9’’',
    },
    {
      image: 'cake-special2.png',
      description: 'Double layer 6’’',
    },
    {
      image: 'cake-special3.png',
      description: 'Single layer 10x14’’',
    },
    {
      image: 'cake-square1.png',
      description: 'Single layer 10x14’’',
    },
    {
      image: 'cake-square2.png',
      description: 'Single layer 10x14’’',
    },
    {
      image: 'cake-square3.png',
      description: 'Single layer 10x14’’',
    },
    {
      image: 'cake-square4.png',
      description: 'Single layer 14x19’’',
    },
       {
      image: 'cake-square5.png',
      description: 'Single layer 14x19’’',
    },
       {
      image: 'cake-square6.png',
      description: 'Single layer 6’’',
    },
    {
      image: 'cake-triple1.png',
      description: 'Triple layer 12’’, 9’’ and 6’’',
    },
    {
      image: 'cake-triple2.png',
      description: 'Triple layer 12’’, 9’’ and 6’’',
    },
    {
      image: 'cake-triple3.png',
      description: 'Triple layer 12’’, 9’’ and 6’’',
    },
  ];

  isOptionsToggle = false;
  isGalleryToggle = false;
  activeCategory: MiskyCakesCategory[] | MiskyGallery[];

  host: string;

  constructor(
    public overlay: Overlay,
    private injector: Injector
  ) { }

  ngOnInit(): void {
    this.host = window.location.host; //localhost:4200
  }

  openGallery(index: number): void {
    const overlayConfig: OverlayConfig = {
      height: '500px',
      width: '500px',
      hasBackdrop: true,
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically()
    };

    const overlayRef = this.overlay.create(overlayConfig);
    overlayRef.backdropClick().subscribe(_ => overlayRef.dispose());

    const data = this.galleryProducts[index].image;
    const cartViewPortal = new ComponentPortal(GalleryViewComponent, null, this.createInjector(data));
    const cartViewPortalRef = overlayRef.attach(cartViewPortal);

    cartViewPortalRef.instance.closeEmitter.subscribe(() => overlayRef.dispose());
  }

  createInjector(d: string): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(CONTAINER_DATA, d);
    return new PortalInjector(this.injector, injectorTokens);
  }

  toggleOptions(): void {
    this.isOptionsToggle = !this.isOptionsToggle;
    this.activeCategory = null;
  }

  showCakes(code?: string): void {
    if (code === undefined) {
      this.isOptionsToggle = false;
      this.isGalleryToggle = true;
    }

    if (code === 'sas') {
      this.activeCategory = this.sizeAndShapeProducts;
    } else if (code === 'of') {
      this.activeCategory = this.flavorProducts;
    } else if (code === 'ss') {
      this.activeCategory = this.specialProducts;
    } else {
      this.activeCategory = this.galleryProducts;
    }
  }
}
