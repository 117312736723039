import { Component, OnInit } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { OrderComponent } from 'src/app/order/order.component';

@Component({
  selector: 'app-menu-home',
  templateUrl: './menu-home.component.html',
  styleUrls: ['./menu-home.component.scss'],
  providers: [ Overlay ]
})
export class MenuHomeComponent implements OnInit {

  // backgroundValue = '/assets/daily.png';

  menuSections = [
    {
      title: 'Daily Misky',
      url: 'daily'
    }, {
      title: 'Custom Cakes',
      url: 'cakes'
    }, {
      title: 'Special Orders',
      url: 'special'
    }
  ];

  constructor(
    public overlay: Overlay
  ) { }

  ngOnInit(): void {
  }

  // updateBackground(index: number): void {
  //   this.backgroundValue = '/assets/' + this.menuSections[index].url + '.png';
  // }
}
