import { Component, OnInit } from '@angular/core';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { AboutSiteComponent } from './about-site/about-site.component';
import { Router } from '@angular/router';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [ Overlay ]
})
export class HomeComponent implements OnInit {

  MenuAdShown = false;

  hoverText = 'Try us!';
  hoverTextArray: string[] = [];

  texts = [
    'Sweet',
    'Dulces',
    'Delicious',
    'Deliciosas',
    'Homemade',
    'Caseros',
    'For You !',
    'Para Ti !',
  ];
  typewriterDisplay = '';
  productSlideshow = [
    'conchas',
    'cookies',
    'cupcake',
    'pan',
  ];

  productShown = '';

  counter = 0;
  counter2 = 0;
  slideshowIndex = 0;

  constructor(
    public overlay: Overlay,
    public router: Router,
  ) { }

  public get getRoute(): string {
    return this.router.url;
  }

  ngOnInit(): void {
    this.hoverTextArray = this.hoverText.split('');

    this.typingCallback();

    this.productShown = this.productSlideshow[0];
    this.enableImgSlideshow();
  }

  typingCallback(): void {
    setInterval(() => {
      this.counter++;
      if (this.counter2 === this.texts.length){
        this.counter2 = 0;
      }
      if (this.counter > this.texts[this.counter2].length + 8) {
        this.counter2++;
        this.counter = 0;
      }
      this.typewriterDisplay = this.texts[this.counter2]?.slice(0, this.counter);
    }, 150);
  }

  enableImgSlideshow(): void {
    setInterval(() => {
      this.productShown = this.productSlideshow[this.slideshowIndex];
      this.slideshowIndex++;
      if (this.slideshowIndex === this.productSlideshow.length){
        this.slideshowIndex = 0;
      }
    }, 6000);
  }

  openAboutSite(): void {
    const overlayConfig: OverlayConfig = {
      height: '550px',
      width: '500px',
      hasBackdrop: true,
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically()
    };

    const overlayRef = this.overlay.create(overlayConfig);
    overlayRef.backdropClick().subscribe(_ => overlayRef.dispose());

    const AboutSitePortal = new ComponentPortal(AboutSiteComponent, null);
    const AboutSitePortalRef = overlayRef.attach(AboutSitePortal);

    AboutSitePortalRef.instance.closeEmitter.subscribe(() => {
      overlayRef.dispose();
    });
  }
}
