import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { CONTAINER_DATA } from '../menu-daily/menu-daily.component';
import { MiskyProduct, MiskyCartItem } from '../../types';

@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.scss']
})
export class ProductViewComponent implements OnInit {

  CART_WORKING_HOURS = [ 6, 19 ];
  BURRITO_ORDER_HOURS = [ 6, 13];
  itemqty: any;
  productData: MiskyProduct;

  closeAnimation = false;

  chosenFlavor: string = null;

  closeEmitter = new EventEmitter<MiskyCartItem>();

  constructor(@Inject(CONTAINER_DATA) public componentData: MiskyProduct) {
    this.itemqty = 1;
  }

  ngOnInit(): void {
    this.productData = this.componentData;
  }

  get isFlavorMandatory(): boolean {
    return (this.productData.flavors?.length || 0) > 0;
  }

  close(isAdding?: boolean): void {
    if (isAdding) {
      if (this.isBetweenInterval(this.CART_WORKING_HOURS)) {
        const isBurrito = this.productData.name.includes('burrito');
        if (isBurrito && !this.isBetweenInterval(this.BURRITO_ORDER_HOURS)) {
          alert(`Sorry! Unfortunately burritos are only available for ordering between ${this.BURRITO_ORDER_HOURS[0]}:00 and ${this.BURRITO_ORDER_HOURS[1]}:00 ⏰`)
        } else if (this.itemqty + (this.productData.previousQuantity || 0) > 15) {
          alert('Want more than 15? Please, request a "Special Order" to be delivered upon availability 😉');
        } else {
          this.closeAnimation = true;
  
          setTimeout(() => {
            const itemToAdd: MiskyCartItem = {
              product: this.productData,
              flavor: this.chosenFlavor,
              quantity: this.itemqty
            };
  
            this.closeEmitter.emit(itemToAdd);
          }, 500);
        }
      } else {
        alert(`Unfortunaly, shopping cart is only available between ${this.CART_WORKING_HOURS[0]}:00 and ${this.CART_WORKING_HOURS[1]}:00 ⏰`);
      }
    } else {
      this.closeEmitter.emit();
    }
  }

  isBetweenInterval(hoursInterval: number[]): boolean {
    const dateNow = new Date();
    const now = { hour: dateNow.getHours(), minutes: dateNow.getMinutes() };
    // const now = { hour: 10, minutes: 0 }; // for hard coding
    if (now.hour >= hoursInterval[0] && now.hour < hoursInterval[1]) {
      return true;
    } else {
      return false;
    }
  }

 // increment item
  inc(): any {
    if (this.isBetweenInterval(this.CART_WORKING_HOURS)) {
      // TODO: Check if some products have limited stock
      if (this.itemqty >= 15) {
        this.itemqty = 15;
      } else if (this.itemqty + (this.productData.previousQuantity || 0) > 15) {
        alert('Want more than 15? Please, request a "Special Order" to be delivered upon availability 😉');
      } else {
      this.itemqty += 1;
      }
    } else{
      alert(`Unfortunaly, shopping cart is only available between ${this.CART_WORKING_HOURS[0]}:00 and ${this.CART_WORKING_HOURS[1]}:00 ⏰`);
    }
  }
  //decrement item
  dec(): any {
    if (this.isBetweenInterval(this.CART_WORKING_HOURS)) {
      if (this.itemqty - 1 < 1){
        this.itemqty = 1;
      } else{
        this.itemqty -= 1;
        }
    } else{
      alert(`Unfortunaly, shopping cart is only available between ${this.CART_WORKING_HOURS[0]}:00 and ${this.CART_WORKING_HOURS[1]}:00 ⏰`);
    }
  }
}
