import { Component, OnInit, InjectionToken, Injector } from '@angular/core';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { CartViewComponent } from '../cart-view/cart-view.component';
import { ProductViewComponent } from '../product-view/product-view.component';
import { MiskyProduct, MiskyCartItem } from '../../types';
import { MISKY_PRODUCTS } from './products';

export const CONTAINER_DATA = new InjectionToken<MiskyProduct>('CONTAINER_DATA');

@Component({
  selector: 'app-menu-daily',
  templateUrl: './menu-daily.component.html',
  styleUrls: ['./menu-daily.component.scss'],
  providers: [ Overlay ]
})
export class MenuDailyComponent implements OnInit {

  products: MiskyProduct[] = MISKY_PRODUCTS;

  cart: MiskyCartItem[] = [];
  CART_WORKING_HOURS = [ 6, 19 ];

  activeFilter = 'bread';
  cartAnimation = false;
  host: string;

  constructor(
    public overlay: Overlay,
    private injector: Injector
  ) { }

  get filteredProducts(): MiskyProduct[] {
    return this.products.filter(p => p.category === this.activeFilter);
  }

  ngOnInit(): void {

    this.host = window.location.host; //localhost:4200
    
    const cacheCart = localStorage.getItem('cartLS');
    if (cacheCart) {
      this.cart = JSON.parse(cacheCart);
    }
  }

  openCart(): void {
    if (this.isBetweenInterval(this.CART_WORKING_HOURS)) {
      
      const overlayConfig: OverlayConfig = {
        height: '500px',
        width: '800px',
        hasBackdrop: true,
        positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically()
      };
  
      const overlayRef = this.overlay.create(overlayConfig);
      overlayRef.backdropClick().subscribe(_ => overlayRef.dispose());
  
      const data = this.cart;
      const cartViewPortal = new ComponentPortal(CartViewComponent, null, this.createInjector(data));
      const cartViewPortalRef = overlayRef.attach(cartViewPortal);
  
      cartViewPortalRef.instance.closeEmitter.subscribe((isFinished?:boolean) => {
        if (isFinished){
          this.cart = [];
          localStorage.removeItem("cartLS");
          localStorage.removeItem("deliveryMethodOptionLS");
        } else {
          overlayRef.dispose();
        }
      });
    } else {
      alert(`Unfortunaly, shopping cart is only available between ${this.CART_WORKING_HOURS[0]}:00 and ${this.CART_WORKING_HOURS[1]}:00 ⏰`);
    }
  }

  isBetweenInterval(hoursInterval: number[]): boolean {
    const dateNow = new Date();
    const now = { hour: dateNow.getHours(), minutes: dateNow.getMinutes() };
    // const now = { hour: 10, minutes: 0 }; // for hard coding
    if (now.hour >= hoursInterval[0] && now.hour < hoursInterval[1]) {
      return true;
    } else {
      return false;
    }
  }

  openDetail(index: number): void {
    const overlayConfig: OverlayConfig = {
      height: '400px',
      width: '650px',
      hasBackdrop: true,
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically()
    };

    const overlayRef = this.overlay.create(overlayConfig);
    overlayRef.backdropClick().subscribe(_ => overlayRef.dispose());

    let data = this.filteredProducts[index];

    const sameProductIndex = this.cart.findIndex(prod => prod.product.name === this.filteredProducts[index].name);

    if (sameProductIndex > - 1) {
      data = {...data, previousQuantity: this.cart[sameProductIndex].quantity };
    }

    const productViewPortal = new ComponentPortal(ProductViewComponent, null, this.createInjector(data));
    const productViewPortalRef = overlayRef.attach(productViewPortal);

    productViewPortalRef.instance.closeEmitter.subscribe((item?: MiskyCartItem) => {
      // TODO: check if item (DONEE!) and flavor are the same to make a merge

      if (item) {
        if (sameProductIndex > -1 && this.cart[sameProductIndex].flavor === item.flavor) {
          this.cart[sameProductIndex].quantity += item.quantity;
        } else {
          this.cart.push(item);
        }
        localStorage.setItem("cartLS", JSON.stringify(this.cart));
        this.cartAnimation = true;
      }
      overlayRef.dispose();

      if (this.cartAnimation) {
        setTimeout(() => {
          this.cartAnimation = false;
        }, 2000);
      }
    });
  }

  createInjector(d: MiskyProduct | MiskyCartItem[]): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(CONTAINER_DATA, d);
    return new PortalInjector(this.injector, injectorTokens);
  }
}
