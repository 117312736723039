import { Component, OnInit, EventEmitter, Input, Inject } from '@angular/core';
import { CONTAINER_DATA } from '../menu-cakes/menu-cakes.component';

@Component({
  selector: 'app-gallery-view',
  templateUrl: './gallery-view.component.html',
  styleUrls: ['./gallery-view.component.scss']
})
export class GalleryViewComponent implements OnInit {

  closeEmitter = new EventEmitter<void>();
  cakeImage = 'template.png';

  constructor(
    @Inject(CONTAINER_DATA) public componentData: string
  ) { }

  ngOnInit(): void {
    this.cakeImage = this.componentData;
  }

  close(): void {
    this.closeEmitter.emit();
  }
}
