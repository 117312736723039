
<!-- Container -->
<div class="container-img">
  <div class="box-img flex-column">
    <img class="img-product" [src]="'assets/Gallery/' + cakeImage">
    <img class="watermark" src="assets/Icons/miskywhite.svg">
  </div>
  <div class="close-container" (click)="close()">
    <img class="close" src="assets/Icons/closeb-1view.svg">
    <img class="fill" src="assets/Icons/closeb-2view.svg">
  </div>
</div>