<!-- <div> -->
  <app-colum-image class="mobile-colimg" [images]="['cake-faq', 'alfajores-faq', 'bread-faq', 'cuernito-faq', 'muffin-faq']"></app-colum-image>
<!-- </div> -->

<!-- Contenedor -->
<div class="content-text contentfade-effect">
  <!-- Primer subtitulo -->
  <h3 class="subtitlepink">Frecuently Asked Questions</h3>
  <!-- Primer contenido -->
  <div class="pregunta flex-column" *ngFor="let item of faqs; index as i">
    <div class="section flex-row" (click)="toggleElement(i)">
      <span class="signos">{{ item.isVisible ? '-' : '+' }}</span>
      <h4 [class.show]="item.isVisible">{{ item.question }}</h4>
    </div>
    <p [class.showing-answer]="item.isVisible">{{ item.answer }}</p>
  </div>
</div>

<!-- Image Colum -->
<!-- <div> -->
  <app-colum-image class="aside-colimg" [images]="['cake-faq', 'alfajores-faq', 'bread-faq', 'cuernito-faq', 'muffin-faq']"></app-colum-image>
<!-- </div> -->
