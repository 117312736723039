<!-- Headline -->
<div class="headline flex-row">
  <h4 class="boldpink" [routerLink]="['/menu']">< Back</h4>
  <h3 class="subtitlepink title">Custom Cakes</h3>
</div>

<div class="container">
<!-- Column MOBILE -->
  <div class="mobile-selection">
    <h4 class="bold select box" [class.select-selected]="isOptionsToggle" (click)="toggleOptions()">Options</h4>
      <div class="column flex-column" *ngIf="isOptionsToggle">
          <h4 class="small" [class.small-act]="activeCategory == sizeAndShapeProducts" (click)="showCakes('sas')">Size and shape</h4>
          <h4 class="small" [class.small-act]="activeCategory == flavorProducts" (click)="showCakes('of')">Our flavors</h4>
          <h4 class="small" [class.small-act]="activeCategory == specialProducts" (click)="showCakes('ss')">Special selections</h4>
      </div>
    <h4 class="bold select box" [class.select-selected]="activeCategory == galleryProducts" (click)="showCakes()">Gallery</h4>
</div>

  <!-- Column A SIDE -->
  <div class="aside-selection flex-column">
    <!-- Top Buttons -->
        <h4 class="bold select" [class.select-selected]="isOptionsToggle" (click)="toggleOptions()">Options</h4>
      <div class="column flex-column" *ngIf="isOptionsToggle">
          <h4 class="bold small" [class.small-act]="activeCategory == sizeAndShapeProducts" (click)="showCakes('sas')">Size and shape</h4>
          <h4 class="bold small" [class.small-act]="activeCategory == flavorProducts" (click)="showCakes('of')">Our flavors</h4>
          <h4 class="bold small" [class.small-act]="activeCategory == specialProducts" (click)="showCakes('ss')">Special selections</h4>
      </div>
      <h4 class="bold select" [class.select-selected]="activeCategory == galleryProducts" (click)="showCakes()">Gallery</h4>
    
    <!-- Request a quote //Hiden until new client confirmation -->
    <!-- <a href="mailto:miskybakerylincoln@gmail.com" target="_blank">
      <button class="quote flex-row">
        <h4 class="white">Request Quote | </h4>
        <img class="contacticon" src="assets/Icons/email-white.svg">
      </button>
     </a> -->
    <!-- link -->
    <!-- <a [href]="'http://'+ host + '/assets/MiskyBakery(CakesMenu).pdf'" download="MiskyBakery(CakesMenu).pdf">
      <h4 class="subpinklight" >Download Cakes Menu PDF</h4>
    </a> -->
  </div>

  <!-- Options HOME view -->
  <div class="home-info" *ngIf="!activeCategory">
    <p>We want to be part of the special events of your life, so we show you our great selection of our creations so far!</p>
    <h3 class="smallboldpink">Pricing</h3>
    <p>Our price includes a custom message on top and any of your selection of “Our flavors” (sponge, frosting and filling). This price may vary depending on your design and special selections will increase it.</p>
    <h3 class="smallboldpink">We love Challenges!</h3>
    <p>If you have a custom design or flavor in mind, send us a message and we will send you a quote!</p>
  </div>
 
  <!-- Product Cards -->
  <div class="cont-global" *ngIf="activeCategory?.length > 0">
    <ng-container *ngIf="isOptionsToggle; else showGallery">
      <ng-container *ngFor="let category of activeCategory">
        <h3 *ngIf="isOptionsToggle" class="smallboldpink left">{{category.name}}</h3>
        <div class="cont-cards flex-row">
            <app-cake-card [data]="cake" *ngFor="let cake of category.cakes"></app-cake-card>
            <div *ngIf="category.cakes?.length" class="ghost-product"></div>
            <div *ngIf="category.cakes?.length" class="ghost-product"></div>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #showGallery>
      
      <!-- Gallery cards -->
      <div class="gallery-cards flex-row">
        <app-gallerycard [data]="cake" *ngFor="let cake of activeCategory; index as i" (click)="openGallery(i)"></app-gallerycard>
        <div *ngIf="activeCategory.length" class="ghost-product"></div>
      </div>
    </ng-template>
  </div>

</div>