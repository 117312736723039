<div class="only-cart" *ngIf="!isCheckOutHidden && !isCheckOutConfirmed">
<!-- headline -->
<div class="headline flex-row">
  <div class="close-button" (click)="close()">
    <img class="close" src="assets/Icons/closeb-pink-1view.svg">
    <img class="fill" src="assets/Icons/closeb-pink-2view.svg">
  </div>
  <h3 class="smallboldpink"> MY ORDER </h3>
</div>

<!-- Container  CART-->
<div class="container-cart">
<!-- Product Selected Info  LEFT COLUMN-->
  <div class="display-product flex-column">
    <div class="product-cart flex-row" *ngFor="let item of cartItems; index as i">
      <div class="images">
        <img class="img-product flex-column" [src]="'assets/Menu/' + item.product.image">
      </div>
    
      <div class="product-selected flex-column">
        <div class=" title-product flex-row">
          <h4 class="bold less">{{ item.product.name }}</h4>
          <h4 class="small"> $ {{ item.product.price * item.quantity | number: '1.2-2'}}</h4>
        </div>
        <div class="on-top flex-row" *ngIf="item.flavor">
          <h4 class="small">Flavor </h4> 
          <h4 class="small"> : {{item.flavor}}</h4>
        </div>
        <div class="qqty-info flex-row">
          <!-- <h4 class="small">Units </h4> -->
          <h4 class="small design" (click)="dec(i)">-</h4>
          <h4 class="small"> {{ item.quantity }}</h4>
          <h4 class="small design" (click)="inc(i)">+</h4>
        </div>
        <h5 class="liitpink" (click)="remove(i)">Remove</h5>
      </div>
    </div>
    <div class="removing" *ngIf="removedCartItems.length > 0">
      <span class="removed" *ngFor="let item of removedCartItems; index as i">
        Removed: "{{item.product.name}}".
        <span class="undo" (click)="addAgainToCart(i)">Undo?</span>
      </span>
    </div>
  </div>

<!-- Final Info RIGHT COLUMN -->
  <div class="checkout flex-column">
<!-- Charge Detail -->
  <div class="charge-box">
    <div class="charge flex-row">
      <h4 class="size">Sub Total</h4>
      <h4 class="size">$ {{ calculateSubtotal | number: '1.2-2' }}</h4>
    </div>
    <div class="charge flex-row" *ngIf="distribution == 'delivery'">
      <h4 class="size">Delivery Fee</h4>
      <h4 class="size">$ {{ deliveryFee | number: '1.2-2' }}</h4>
    </div>
    <div class="charge flex-row" *ngIf="distribution == 'pickup'">
      <h4 class="size">In-store Pickup</h4>
      <h4 class="size">$ 0.00</h4>
    </div>
    <div class="charge total flex-row">
      <h4 class="bold">Total</h4>
      <h4 class="bold" *ngIf="distribution == 'delivery'">$ {{ calculateSubtotal + deliveryFee | number: '1.2-2' }}</h4>
      <h4 class="bold" *ngIf="distribution == 'pickup'">$ {{ calculateSubtotal | number: '1.2-2' }}</h4>
    </div>
  </div>
<!-- Special request box -->
    <div class="request-box flex-row">
      <h4 class="boldpink">+</h4>
      <textarea placeholder="Special requests" rows="3" [(ngModel)]="specialRequest"></textarea>
    </div>
<!-- PU or Delivery buttons option -->
    <form>
    <div class="distribution">
      <div class="option flex-row">
        <label class="top flex-row">
          <input type="radio" name="whatev" value="pickup"  [(ngModel)]="distribution" (ngModelChange)="updateDistributionLS()">
          <h4 class="space size">Pick Up</h4>
        </label>
        <h5 class="space-top">(Today, 10AM-7PM)</h5>
      </div>
      <div class="option flex-row" >
        <label class="flex-row">
          <!-- !(F && F) = T (disabled); !(F && T) = T (disabled); !(T && F) = T (disabled); !(T && T) = F (enabled) -->
          <input type="radio" name="whatev" value="delivery" [attr.disabled]="true" [(ngModel)]="distribution" (ngModelChange)="updateDistributionLS()">
          <h4 class="space size lad" [class.label-dis]="deliveryUnavailable !== null ? true : false">Delivery</h4>
        </label>
        <h5 class="margin-mob lad" [class.label-dis]="deliveryUnavailable !== null ? true : false">(2PM-4PM)</h5>
      </div>
    </div>
    <div class="align-mobile flex-row">
      <!-- <h5 class="restriction" *ngIf="!subtotalGreater20">(Min order of $20.00) </h5> -->
      <!-- <h5 class="restriction" *ngIf="!(isBetweenInterval(DELIVERY_HOURS))"> (Orders from 6AM to 3PM) </h5> -->
     </div>
    </form>
<!-- Last buttons -->
    <div class="bottom-box">
      <button class="out-button flex-row" [disabled]="distribution === '' || calculateSubtotal == 0" (click)="showCheckOut()">
        <h1 class="smallwhite">Check Out</h1>
      </button>
      <h1 class="smallpink effect" (click)="close()">Continue Shopping</h1>
    </div>
  </div>
</div>
</div>



<!-- CHECKOUT Overlay-->
<div class="only-checkout flex-column" *ngIf="isCheckOutHidden && !isCheckOutConfirmed" >
  <!--headline BACK-->
  <div class="headline flex-row">
    <h4 class="boldpink" (click)="closeCheckOut()" >< Back</h4>
    <h3 class="smallboldpink title">CHECK OUT DETAILS</h3>
  </div>  
  <div class="container-checkout">

    <!-- order  summary LEFT COLUMN-->
    <div class="summary flex-column">

      <div class="content-products">
        <div class="product-info" *ngFor="let item of cartItems; index as i"> 
          <!-- product name   qqtyxprice-->
          <div class="adjust flex-row">
            <h4 class="bold tam pro">{{ item.product.name }}</h4>
            <div class="qqty-price flex-row">
              <h4 class="bold">
                {{ item.quantity }}&nbsp;x&nbsp;{{ item.product.price | number: '1.2-2'}} US$
              </h4>
            </div>
          </div>
          <!-- flavor-->
          <div class="flex-row" style="align-items:center;" *ngIf="item.flavor">
            <h4 class="small">Flavor </h4> 
            <h4 class="small tam-space">: </h4> 
            <h4 class="small"> {{item.flavor}}</h4>
          </div>
        </div>
      </div>
      <!-- Charge Detail -->
      <div class="total-box">
        <div class="align-charge flex-row">
          <h4 class="size">Sub Total</h4>
          <h4 class="size"> {{ calculateSubtotal | number: '1.2-2' }} US$</h4>
        </div>
        <div class="align-charge flex-row" *ngIf="distribution == 'delivery'">
          <h4 class="size">Delivery Fee</h4>
          <h4 class="size">{{ deliveryFee | number: '1.2-2' }} US$</h4>
        </div>
        <div class="align-charge flex-row" *ngIf="distribution == 'pickup'">
          <h4 class="size">In-store Pickup</h4>
          <h4 class="size">0.00 US$</h4>
        </div>
        <div class="align-charge flex-row">
          <h4 class="bold">Total</h4>
          <h4 class="bold" *ngIf="distribution == 'delivery'"> {{ calculateSubtotal + deliveryFee | number: '1.2-2' }} US$</h4>
          <h4 class="bold" *ngIf="distribution == 'pickup'"> {{ calculateSubtotal | number: '1.2-2' }} US$</h4>
        </div>
      </div>

    </div>

    <!-- <form [formGroup]="checkoutForm" class="last-details flex-column" action="https://formspree.io/f/mgepkadz" method="POST"> -->
    <form [formGroup]="checkoutForm" class="last-details flex-column" (ngSubmit)="submitForm()">
      <!--Custom Details RIGHT COLUMN-->
      <div class="cust-details flex-column">
        <h4 class="sub-title">YOUR DETAILS</h4>
        <!-- basic data cust-->
          <input
            class="cust-form email-box"
            [class.input-error]="formControl.emailAddress.touched && (formControl.emailAddress.errors?.required || formControl.emailAddress.errors?.pattern)"
            placeholder="Email address"
            formControlName="emailAddress"
            name="emailAddress"
          />
          
          <h4 class="alert-text" *ngIf="formControl.emailAddress.touched && formControl.emailAddress.errors?.pattern">
            Email address must be valid
          </h4>
          <h4 class="alert-text" *ngIf="formControl.emailAddress.touched && formControl.emailAddress.errors?.required">
            Field required
          </h4>

          <input 
          [class.input-error]="formControl.firstName.touched && (formControl.firstName.errors?.required || formControl.firstName.errors?.pattern)"
          class="cust-form first-name" 
          placeholder="First name" 
          formControlName="firstName"
         >
          <h4 class="alert-text" *ngIf="formControl.firstName.touched && formControl.firstName.errors?.pattern">
            Name must be valid
          </h4>
          <h4 class="alert-text" *ngIf="formControl.firstName.touched && formControl.firstName.errors?.required">
            Field required
          </h4>

          <input 
          [class.input-error]="formControl.lastName.touched && (formControl.lastName.errors?.required || formControl.lastName.errors?.pattern)"
          class="cust-form last-name" 
          placeholder="Last name" 
          formControlName="lastName"
          >
          <h4 class="alert-text" *ngIf="formControl.lastName.touched && formControl.lastName.errors?.pattern">
            Last name must be valid
          </h4>
          <h4 class="alert-text" *ngIf="formControl.lastName.touched && formControl.lastName.errors?.required">
            Field required
          </h4>

          <div class="flex-row phone-detail">
            <h4 class="code">+1</h4>
            <input 
            [class.input-error]="formControl.phoneNumber.touched && (formControl.phoneNumber.errors?.required || formControl.phoneNumber.errors?.pattern)"
            class="cust-form phone-box" 
            placeholder="Phone number" 
            formControlName="phoneNumber"
            >
          </div>
          <h4 class="alert-phone" *ngIf="formControl.phoneNumber.touched && formControl.phoneNumber.errors?.pattern">
            Phone number must be valid
          </h4>
          <h4 class="alert-phone" *ngIf="formControl.phoneNumber.touched && formControl.phoneNumber.errors?.required">
            Field required
          </h4>

        <h4 class="disclaimer">By providing your phone number and email address, you agree to receive a phone call or email related to your order. </h4>
      </div>

      <!-- Delivery Details RIGHT COLUMN-->
      <div class="dev-details flex-column" *ngIf="distribution == 'delivery'">
        <h4 class="sub-title">DELIVERY DETAILS</h4>
        <!-- info holder-->
        <div class="dev-restrictions flex-row">
          <h5 class="label-dev">$20.00 min. |</h5>
          <h5 class="label-dev">$3.00 Delivery Fee |</h5>
          <h5 class="label-dev">Payment: Cash upon delivery/ </h5>
          <h5 class="label-dev">Card during phone confirmation</h5>
        </div>
          <!-- data cust delivery-->
          <h4 class="area-disclaimer">Our available delivery area in Lincoln is from: 
            <span class="highlight">Superior Street</span>  to <span  class="highlight">South Street </span>
            and 
            <span  class="highlight">N First Street</span> to <span  class="highlight">North 70th Street</span>
            <!-- map-->
            <a href="https://www.google.com/maps/d/u/0/edit?mid=1B_bKslLOu-08eIBtCmPC6Xx-ucX3_9b7&fbclid=IwAR3jtmBLPyCjNzrajPur5HDDmFtCDMdY_8w3luHp2OBX6_VSWyVEnzbbxPA&ll=40.82452722201206%2C-96.67495765000001&z=13"  
            target="_blank">
              <span class="map">Show map</span>
            </a>
          </h4>
          <!--combobox area-->
          <select 
          [class.select-error]="formControl.addressArea.touched && (formControl.addressArea.errors?.required)"
          class="choose-area" 
          formControlName="addressArea">

            <option class="area-holder" [ngValue]="null">Select your area...</option>
            <option class="area" *ngFor="let area of deliveryAreas">{{ area }}</option>
          </select>
          <h4 class="alert-text" *ngIf="formControl.addressArea.touched && formControl.addressArea.errors?.required">
            Field required
          </h4>

          <input 
          [class.input-error]="formControl.address1.touched && (formControl.address1.errors?.required)"
          class="cust-form delivery-box1" 
          placeholder="Address 1" 
          formControlName="address1"
          >
          <h4 class="alert-text" *ngIf="formControl.address1.touched && formControl.address1.errors?.required">
            Field required
          </h4>
          <input class="cust-form delivery-box2" placeholder="Address 2" formControlName="address2"   >
          <input class="cust-form delivery-extra" placeholder="Apt., Floor, Suite, etc. (Optional)" formControlName="addressExtra" >
      </div>


      <!-- Bottom-->
      <button class="out-button position-button flex-row" type="submit"  [disabled]="!isValid" >
        <h1 class="smallwhite">Confirm</h1>
      </button>
    </form>
  </div>
</div>


<!-- Confirmation Overlay -->
<div class="flex-column confirmation-box" *ngIf="isCheckOutConfirmed">
  
  <div class="close-container marg" (click)="close()">
    <img class="close" src="assets/Icons/closeb-1view.svg">
    <img class="fill" src="assets/Icons/closeb-2view.svg">
  </div>

  <div class="thanks-content flex-row">
    <img class="thanks-img" src="assets/thanks-img.png">
    <div class="thanks-quote flex-column">
      <h4 class="white al in-text">Your order is being processed by our 
        <span class="highlight white">
        Team 
        </span>
      and you would receive a confirmation from us shortly. 
      </h4>
      <div class="order-quote">
        <h4 class="white al in-text">Order Number:</h4>
        <h4 class="white order-number">
          {{orderCode}}
        </h4>
      </div>
      <div class="flex-column al">
        <h4 class="small white tp">If you would like to speak to someone inmediatly, feel free to call:</h4>
        <button class="out-button place flex-row wb" (click)="close()">
          <a href="tel:402 742-0017">
            <h1 class="smallpink">+1 402-742-0017</h1>
          </a>
        </button>
        <h4 class="small white top-high">Or chat with us!</h4>
      </div>
    </div>
  </div>

  </div>

