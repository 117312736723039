export const DELIVERY_AREAS = [
  '40th & A',
  'Antelope Park',
  'Belmont',
  'Bethany',
  'Capitol View',
  'Clinton',
  'Downtown',
  'East Campus',
  'Eastridge',
  'Everett',
  'Hartley',
  'Havelock',
  'Hawley',
  'Haymarket',
  'Landons',
  'Malone',
  'Near South',
  'Piedmont',
  'Riley',
  'North Bottoms',
  'South Bottoms',
  'South Salt Creek',
  'Sunset Acres',
  'University Place',
  'Witherbee',
  'Woods Park',
]