import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayContainer, FullscreenOverlayContainer } from '@angular/cdk/overlay';
import { FacebookModule } from 'ngx-facebook';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { MenuComponent } from './menu/menu.component';
import { MenuHomeComponent } from './menu/menu-home/menu-home.component';
import { MenuDailyComponent } from './menu/menu-daily/menu-daily.component';
import { MenuCakesComponent } from './menu/menu-cakes/menu-cakes.component';
import { MenuSpecialComponent } from './menu/menu-special/menu-special.component';
import { MenuCardComponent } from './menu/menu-card/menu-card.component';
import { OrderComponent } from './order/order.component';
import { FaqComponent } from './faq/faq.component';
import { ContactComponent } from './contact/contact.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ColumImageComponent } from './colum-image/colum-image.component';
import { CakeCardComponent } from './menu/cake-card/cake-card.component';
import { GalleryCardComponent } from './menu/gallerycard/gallerycard.component';
import { CartViewComponent } from './menu/cart-view/cart-view.component';
import { ProductViewComponent } from './menu/product-view/product-view.component';
import { GalleryViewComponent } from './menu/gallery-view/gallery-view.component';
import { AboutSiteComponent } from './home/about-site/about-site.component';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    MenuComponent,
    MenuHomeComponent,
    MenuDailyComponent,
    MenuCakesComponent,
    MenuSpecialComponent,
    MenuCardComponent,
    OrderComponent,
    FaqComponent,
    ContactComponent,
    NavbarComponent,
    ColumImageComponent,
    CakeCardComponent,
    GalleryCardComponent,
    CartViewComponent,
    ProductViewComponent,
    GalleryViewComponent,
    AboutSiteComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FacebookModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [{provide: OverlayContainer, useClass: FullscreenOverlayContainer}],
  bootstrap: [AppComponent]
})
export class AppModule { }
