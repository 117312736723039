export const MISKY_PRODUCTS = [
  {
    image: 'bolillos-bread.png',
    name: 'Bolillos 5ct',
    description: 'Classic Mexican white bread (5ct)',
    price: 2.00,
    category: 'bread',
    gluten: 'No',
    vegan: 'Yes',
  },
  {
    image: 'empty.png',
    name: 'Telera Bread',
    description: 'Classic Mexican torta bread (1ct)',
    price: 0.65,
    category: 'bread',
    gluten: 'No',
    vegan: 'Yes',
  },
  {
    image: 'barritas-cookies.png',
    name: 'Barritas',
    description: 'Crunchy traditional Mexican cookie (1ct)',
    price: 1.00,
    category: 'cookies',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'colors-cookies.png',
    name: 'Colors',
    description: 'Colourful, soft and chewy cookie (1ct)',
    price: 1.00,
    category: 'cookies',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'champurrados-cookies.png',
    name: 'Champurrados',
    description: 'Deliciously crisp Guatemalan cookies coated in Sugar (1ct)',
    price: 1.00,
    category: 'cookies',
    gluten: 'No',
    vegan: 'Yes',
  },
  {
    image: 'champurradossesame-cookies.png',
    name: 'Champurrados sesame',
    description: 'Deliciously Crisp cookies with sesame seeds (1ct)',
    price: 1.00,
    category: 'cookies',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'chocolate-cookies.png',
    name: 'Chocolate Chip',
    description: 'Traditional crispy chocolate chip cookie (1ct)',
    price: 1.00,
    category: 'cookies',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'domino-cookies.png',
    name: 'Domino',
    description: 'Soft, buttery cookie (1ct)',
    price: 1.00,
    category: 'cookies',
    gluten: 'Yes',
    vegan: 'No',
  },
  {
    image: 'guayabas-cookies.png',
    name: 'Guayabas',
    description: 'Fluffy sweet bread with raisins, coconut or walnuts (1ct)',
    price: 1.00,
    category: 'cookies',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'minibuttery-cookies.png',
    name: 'Mini Butter cookies 6ct',
    description: 'Delightful Soft mini buttery cookies (5ct) ',
    price: 1.75,
    category: 'cookies',
    gluten: 'No',
    vegan: 'No',
    flavors: [
      'Strawberry jam',
      'Pineapple jam',
      'Sweetened apple',
      'Sprinkles',
    ],
  },
  {
    image: 'pasticeta-cookies.png',
    name: 'Pasticeta',
    description: 'Buttery cookies with cream (1ct)',
    price: 1.25,
    category: 'cookies',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'polvorones-cookies.png',
    name: 'Polvorones',
    description: 'Delightful Soft buttery cookies (1ct)',
    price: 1.00,
    category: 'cookies',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'polvoronesnuez-cookies.png',
    name: 'Polvorones walnuts',
    description: 'Delightful Soft buttery cookies with walnuts (1ct)',
    price: 1.00,
    category: 'cookies',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'smiley-cookies.png',
    name: 'Smiley',
    description: 'Soft and chewy with a smiley face to make your day! (1ct)',
    price: 1.00,
    category: 'cookies',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'sprinkles-cookies.png',
    name: 'Sprinkles',
    description: 'Colourful, soft and chewy cookie (1ct)',
    price: 1.00,
    category: 'cookies',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'tricolor-cookies.png',
    name: 'Tricolor',
    description: 'Soft, buttery and colourful cookie (1ct) ',
    price: 1.00,
    category: 'cookies',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'empty.png',
    name: 'Watermelon',
    description: 'Watermelon shaped crispy chocolate cookie (1ct)',
    price: 1.00,
    category: 'cookies',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'carlotas-dessertscup.png',
    name: 'Carlotas',
    description: 'Tres leches cake in a cup (1ct)',
    price: 3.50,
    category: 'cup',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'empty.png',
    name: 'Flan',
    description: 'Traditional Latin-American dessert made with eggs and caramel base (1ct)',
    price: 3.25,
    category: 'cup',
    gluten: 'Yes',
    vegan: 'No',
  },
  {
    image: 'empty.png',
    name: 'Fruit cups',
    description: 'Seasonal fruit with lemon juice, tajín and salt on top (1ct)',
    price: 3.99,
    category: 'cup',
    gluten: 'Yes',
    vegan: 'Yes',
  },
  {
    image: 'empty.png',
    name: 'Jello',
    description: 'Mexican milk jello with little pieces of traditional jello inside (1ct)',
    price: 3.25,
    category: 'cup',
    gluten: 'Yes',
    vegan: 'No',
  },
  {
    image: 'saltenas-savory.png',
    name: 'Chicken salteñas',
    description: 'Traditional Bolivian empanadas with a spicy chicken and potato filling (1ct)',
    price: 3.00,
    category: 'savory',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'chickentamal-savory.png',
    name: 'Chicken tamales',
    description: 'Traditional Mexican tamales filled with chicken and spicy green sauce (1ct)',
    price: 1.75,
    category: 'savory',
    gluten: 'Yes',
    vegan: 'No',
  },
  {
    image: 'chicharrones-savory.png',
    name: 'Chicharrones',
    description: 'Fried Mexican Wheat Crisps (1ct)',
    price: 2.50,
    category: 'savory',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'chicharronburrito-savory.png',
    name: 'Chicharron burrito',
    description: 'Spicy pork grinds with beans wrapped in a homemade flour tortilla (1ct)',
    price: 4.00,
    category: 'savory',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'empty.png',
    name: 'Egg, chorizo & potato burrito',
    description: 'Scrambled eggs,sausage, potatoes and beans wrapped in homemade flour tortilla (1ct)',
    price: 4.00,
    category: 'savory',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'empty.png',
    name: 'Egg & cheese croissant',
    description: 'Our delicious croissant filled with egg and cheese for breakfast pre-order (1ct)',
    price: 4.00,
    category: 'savory',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'jalapenobread-savory.png',
    name: 'Jalapeño Bread',
    description: 'Bolillo bread filled with jalapeño and cream cheese (1ct)',
    price: 3.25,
    category: 'savory',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'meatburrito-savory.png',
    name: 'Meat burrito',
    description: 'Spicy beef and potatoes with rice and beans wrapped in homemade flour tortilla (1ct)',
    price: 4.00,
    category: 'savory',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'porktamal-savory.png',
    name: 'Pork tamales',
    description: 'Traditional Mexican tamales filled with pork and spicy red sauce (1ct)',
    price: 1.75,
    category: 'savory',
    gluten: 'Yes',
    vegan: 'No',
  },
  {
    image: 'carrotslice-cake.png',
    name: 'Slice carrot cake',
    description: 'Sweet, moist one-layer carrot cake topped with cream cheese frosting (slice)',
    price: 3.25,
    category: 'cakes',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'carrot-cake.png',
    name: 'Carrot cake',
    description: 'Sweet, moist one-layer carrot cake topped with cream cheese frosting (12 servings)',
    price: 38.00,
    category: 'cakes',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'cheesecakeslice-cake.png',
    name: 'Slice cheesecake',
    description: 'Traditional cheesecake (slice)',
    price: 3.50,
    category: 'cakes',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'cheesecake-cake.png',
    name: 'Cheesecake',
    description: 'Traditional cheesecake (12 servings)',
    price: 40.00,
    category: 'cakes',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'pineappleslice-cake.png',
    name: 'Slice pineapple upside down',
    description: 'Sweet, moist one-layer carrot dough topped with cream cheese frosting (slice)',
    price: 3.25,
    category: 'cakes',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'empty.png',
    name: 'Pineapple upside down cake',
    description: 'Traditional pineapple upside down cake (12 servings)',
    price: 30.00,
    category: 'cakes',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'empty.png',
    name: 'Slice thousand layer cake',
    description: 'Puff pastry filled with whipped and bavarian cream (slice)',
    price: 3.50,
    category: 'cakes',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'thousandlayer-cake.png',
    name: 'Thousand layer cake',
    description: 'Puff pastry filled with whipped and bavarian cream (12 servings)',
    price: 40.00,
    category: 'cakes',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'empty.png',
    name: 'Slice tres Leches',
    description: 'Traditional Mexican cake soaked in three types of milk (slice)',
    price: 3.50,
    category: 'cakes',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'empty.png',
    name: 'Tres Leches',
    description: 'Traditional Mexican cake soaked in three types of milk (12 servings)',
    price: 40.00,
    category: 'cakes',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'empty.png',
    name: 'Slice cappuccino Cake',
    description: 'Cappuccino flavored cake with whipped cream (slice)',
    price: 3.50,
    category: 'cakes',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'capuccino-cake.png',
    name: 'Cappuccino Cake',
    description: 'Cappuccino flavored cake with whipped cream (12 servings)',
    price: 40.00,
    category: 'cakes',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'alfajores-sweetbread.png',
    name: 'Alfajores',
    description: 'Traditional alfajores filled with “dulce de leche” and tossed in coconut flakes (1ct)',
    price: 1.75,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'banderillas-sweetbread.png',
    name: 'Banderillas',
    description: 'Crispy and flakey puff pastry (1ct)',
    price: 1.25,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'bavariancone-sweetbread.png',
    name: 'Bavarian cone',
    description: 'Flaky crips dough cone filled with Bavarian cream (1ct)',
    price: 1.25,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'bigotes-sweetbread.png',
    name: 'Bigotes',
    description: 'Sweet bread topped with butter and sugar (1ct)',
    price: 1.00,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'borrachos-sweetbread.png',
    name: 'Borrachos',
    description: 'Soft sweet dough shaped like a toast topped with butter cream and sugar(1ct)',
    price: 1.00,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'borregos-sweetbread.png',
    name: 'Borregos',
    description: 'Cinnamon bread filled with strawberry jam (1ct)',
    price: 1.00,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'buttercroissant-sweetbread.png',
    name: 'Butter croissant',
    description: 'Classic French flaky pastry with a buttery-sugar flavored of vanilla inside (1ct)',
    price: 1.00,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'cheesecake-sweetbread.png',
    name: 'Cheesecake Pastry',
    description: 'No crust square cheesecake (1ct)',
    price: 1.25,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'chococherry-sweetbread.png',
    name: 'Chococherry',
    description: 'Small chocolate cake topped with cream and cherry (1ct)',
    price: 1.75,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'churros-sweetbread.png',
    name: 'Churros',
    description: 'Crispy dough coated with sugar a cinnamon (1ct)',
    price: 1.50,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'conchasrosa-sweetbread.png',
    name: 'Conchas',
    description: 'Soft and sweet Mexican sweet bread with a colorful seashell like topping (1ct)',
    price: 1.25,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
    flavors: [
      'Chocolate',
      'Strawberry',
      'Lemon',
      'Vanilla',
    ],
  },
  {
    image: 'creamcortadillo-sweetbread.png',
    name: 'Cortadillo',
    description: 'Vanilla cake topped with strawberry jam or whipped cream (1ct)',
    price: 1.00,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'creamcone-sweetbread.png',
    name: 'Cream cone',
    description: 'Flaky crips dough cone filled with cream (1ct)',
    price: 1.25,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'croissant-sweetbread.png',
    name: 'Croissants',
    description: 'Classic French flaky pastry with cheese (1ct)',
    price: 1.00,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'cupcakes-sweetbread.png',
    name: 'Cupcakes',
    description: 'Individual cake (chocolate/vanilla) topped with cream (1ct)',
    price: 1.75,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
    flavors: [
       'Chocolate',
        'Vanilla',
    ],
  },
  {
    image: 'danish-sweetbread.png',
    name: 'Danish',
    description: 'Flaky pastry filled with strawberry or pineapple jam (1ct)',
    price: 1.25,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
    flavors: [
       'Strawberry jam',
       'Pineapple jam',
    ],
  },
  {
    image: 'donas-sweetbread.png',
    name: 'Donuts',
    description: 'Tossed in cinnamon sugar (1ct)',
    price: 1.00,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'empanada-sweetbread.png',
    name: 'Empanadas',
    description: 'Soft buttery dough filled with jam of your choice (1ct)',
    price: 1.25,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
    flavors: [
      'Pineapple Jam',
      'Sweetened apple chunks',
      'Guava jam',
    ],
  },
  {
    image: 'empanada-sweetbread.png',
    name: 'Sugar empanadas',
    description: 'Soft buttery dough, tossed in sugar and filled with cream, jam or cajeta (1ct)',
    price: 1.25,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
    flavors: [
      'Strawberry Jam',
      'Bavarian cream',
      'Cajeta',
    ],
  },
  {
    image: 'empanada-sweetbread.png',
    name: 'Vegan empanadas',
    description: 'Soft melts-in-your-mouth dough, tossed in sugar and filled with jam (1ct)',
    price: 1.25,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'Yes',
    flavors: [
     'Pineapple Jam',
     'Strawberry Jam',
     'Guava jam',
     'Sweetened apple chunks',
    ],
  },
  {
    image: 'empty.png',
    name: 'Guatemalan barras',
    description: 'Fluffy sweet bread with butter cream frosting (1ct)',
    price: 1.25,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'coronaguatemala-sweetbread.png',
    name: 'Guatemalan corona',
    description: 'Sweet bread made with cotija cheese and topped with sesame seeds (1ct)',
    price: 1.00,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'cortada-sweetbread.png',
    name: 'Guatemalan cortada',
    description: 'Soft sweet dough topped with chocolate sugar (1ct)',
    price: 1.25,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'gusano-sweetbread.png',
    name: 'Guatemalan gusano',
    description: 'Sweet bread made with cotija cheese filled with butercream (1ct)',
    price: 1.00,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'pastaguatemala-sweetbread.png',
    name: 'Guatemalan pasta',
    description: 'Sweet bread made with cotija cheese and topped with buttercream (1ct)',
    price: 1.00,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'ninosenvueltos-sweetbread.png',
    name: 'Niños envueltos',
    description: 'Mexican swiss roll filled with strawberry jam and coated in coconut flakes (1ct)',
    price: 1.00,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'macarrons-sweetbread.png',
    name: 'Macaroons',
    description: 'French traditional pastry (1ct)',
    price: 1.75,
    category: 'sweetbread',
    gluten: 'Yes',
    vegan: 'No',
  },
  {
    image: 'manitas-sweetbread.png',
    name: 'Manitas',
    description: 'Hand shaped flaky pastry filled with pineapple jam (1ct)',
    price: 1.00,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'mantecadas-sweetbread.png',
    name: 'Mantecadas',
    description: 'Buttery cake like sweet bread (1ct)',
    price: 1.00,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'ojosbuey-sweetbread.png',
    name: 'Ojos de pancha',
    description: 'Vanilla sponge with a crisp crust type exterior (1ct)',
    price: 1.25,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'orejas-cookies.png',
    name: 'Orejas',
    description: 'Puff pastry filled with a mixture of cinnamon and sugar (1ct)',
    price: 1.25,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'pandemuerto-sweetbread.png',
    name: 'Pan de muerto',
    description: 'Fluffy orange flavored sweet bread topped with sugar (1ct)',
    price: 2.50,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'empty.png',
    name: 'Pan de yema',
    description: 'Yolk velvety sponge (1ct)',
    price: 1.25,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'panfino-sweetbread.png',
    name: 'Pan fino',
    description: 'Sugary cinnamon flavored soft dough shaped (1ct)',
    price: 1.00,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
    flavors: [
      'Elotes',
      'Sun',
      'Flowers',
      'Cuernitos',
    ],
  },
  {
    image: 'panqueque-sweetbread.png',
    name: 'Panqueque',
    description: 'Vanilla cake like sweet bread topped with walnuts and raisins (1ct)',
    price: 1.00,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'pastrybaskets-sweetbread.png',
    name: 'Pastry Baskets',
    description: 'Soft and chewy basket shaped sweet bread filled with pineapple, strawberry or guava jam (1ct)',
    price: 1.25,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
    flavors: [
      'Pineapple Jam',
      'Strawberry jam',
      'Guava jam',
    ],
  },
  {
    image: 'puerquitos-sweetbread.png',
    name: 'Puerquitos',
    description: 'Mexican pig shaped ginger cookie (1ct)',
    price: 1.00,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'squarepuffpastry-sweetbread.png',
    name: 'Puff Pastry',
    description: 'Square shaped puff pastry with pineapple or strawberry jam (1ct)',
    price: 1.25,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
    flavors: [
       'Pineapple Jam',
       'Strawberry jam',
    ],
  },
  {
    image: 'quequitos-sweetbread.png',
    name: 'Quequitos',
    description: 'Fluffy vanilla white cake muffin (1ct)',
    price: 1.00,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'rebanadas-sweetbread.png',
    name: 'Rebanadas',
    description: 'Soft sweet dough shaped like a toast topped with butter cream (1ct)',
    price: 1.00,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'breadsalvadorean-sweetbread.png',
    name: 'Salvadoran Bread',
    description: 'Sweet bread tossed with sugar (1ct)',
    price: 1.00,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'suspiro-sweetbread.png',
    name: 'Suspiro',
    description: 'Vanilla cake topped with strawberry jam and bavarian cream (1ct)',
    price: 1.00,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'empty.png',
    name: 'Strawberry Tacos',
    description: 'Pastry dessert filled with cream cheese and/or strawberry jam (1ct)',
    price: 1.75,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
    flavors: [
      'Strawberry jam',
      'Cream Cheese and strawberry jam',
    ],
  },
  {
    image: 'empty.png',
    name: 'Trenzas',
    description: 'Sponge like sweet bread (1ct)',
    price: 1.25,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
  {
    image: 'turnover-sweetbread.png',
    name: 'Turnover',
    description: 'Pastry dessert filled with sweetened apples or guava (1ct)',
    price: 1.50,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
    flavors: [
      'Sweetened apple',
      'Guava jam',
    ],
  },
  {
    image: 'yoyos-sweetbread.png',
    name: 'Yoyos',
    description: 'Delicious and fluffy snowball type of pastry filled with strawberry jam or buttercream (1ct)',
    price: 1.00,
    category: 'sweetbread',
    gluten: 'No',
    vegan: 'No',
  },
];
